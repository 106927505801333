import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditCustomerIcon from "@mui/icons-material/ModeEditOutline";

import {
  CustomersWhereQuery,
  useCustomerFullQuery,
} from "../../../generated/graphql";
import CustomerDetailsTable from "./CustomerDetailsTable";
import PaginationButtons from "../../TablePagination";
import theme from "../../../Theme";

interface Props {
  customerData: CustomersWhereQuery;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  currentPage: number;
  totalPages: number;
}

// This is a wrapper to load a table with customer items
const CustomerDetailsTableWrapper: React.FC<{ id: number }> = ({ id }) => {
  const { data, loading, error, refetch } = useCustomerFullQuery({
    variables: {
      where: { id: id },
    },
  });

  useEffect(() => {
    refetch();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return data && data.customer ? (
    <CustomerDetailsTable customer={data} />
  ) : null;
};

const Customers: React.FC<Props> = ({
  customerData,
  handlePrevPage,
  handleNextPage,
  currentPage,
  totalPages,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Customer currently opened
  const [openCustomerId, setOpenCustomerId] = useState<null | number>(null);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ display: "flex", alignItems: "center", mt: 2 }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                DK #
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Name
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Payment
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Addresses
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                UDIDs
              </TableCell>
              <TableCell style={{ padding: "4px" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Typography variant="h4" component="h4" align="center">
                      {currentPage} of {totalPages}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <PaginationButtons
                      onPrev={handlePrevPage}
                      onNext={handleNextPage}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerData.customers.map((customer, _) => (
              <React.Fragment key={customer.id}>
                <TableRow>
                  <TableCell align="center">{customer.dkNumber}</TableCell>
                  <TableCell align="left">{customer.name}</TableCell>
                  <TableCell align="center">{customer.paymentTerm}</TableCell>
                  <TableCell align="center">
                    {customer._count?.addresses}
                  </TableCell>
                  <TableCell align="center">{customer._count?.udids}</TableCell>

                  <TableCell align="right" colSpan={2}>
                    <Tooltip title="Edit customer ...">
                      <IconButton
                        onClick={() => {
                          navigate("/company/" + customer.id);
                        }}
                      >
                        <EditCustomerIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      onClick={() => {
                        if (openCustomerId === customer.id) {
                          setOpenCustomerId(null); // If the customer is currently open, close it
                        } else {
                          setOpenCustomerId(customer.id); // Otherwise, open this customer
                        }
                      }}
                    >
                      {openCustomerId === customer.id ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{
                    display:
                      openCustomerId === customer.id ? "table-row" : "none",
                  }}
                >
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={13}
                  >
                    {openCustomerId === customer.id && (
                      <CustomerDetailsTableWrapper id={customer.id} />
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default Customers;
