import React, { useState } from "react";

import {
  Backdrop,
  Divider,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import ETLJobSearchForm from "./ETLFilesSearchForm";
import ETLFilesTable from "./ETLFilesTable";
import dayjs from "dayjs";
import { useEtlFilesQuery } from "../../generated/graphql";

const PAGE_SIZE = 50; // change this to your desired page size

type SearchParams = {
  dataset: string | null;
  dateFrom: Date | null;
  dateTo: Date | null;
};

const ETLFilesList = () => {
  const [searchParams, setSearchParams] = useState<SearchParams>({
    dataset: "",
    dateFrom: dayjs().subtract(30, "day").toDate(), // first day of the current month
    dateTo: dayjs().toDate(), // today
  });
  const [uploadComplete, setUploadComplete] = useState(false);
  // This state will hold the submitted search params
  const [submittedParams, setSubmittedParams] = useState<SearchParams>({
    dataset: "",
    dateFrom: dayjs().subtract(30, "day").toDate(),
    dateTo: dayjs().toDate(), // today
  });
  const [page, setPage] = useState(1);

  const handleSearch = (params: SearchParams) => {
    setPage(1);
    setSubmittedParams(params);
  };

  const handleUploadComplete = (filename: string) => {
    setUploadComplete(true);
  };

  const hasParams = (params: SearchParams) => {
    // return true if any of the string params is not null or empty
    if (params.dataset !== null) return true;
    //if (params.status !== null && params.status !== 'ALL') return true;
    // return true if any of the dates is not null
    if (params.dateFrom !== null) return true;
    if (params.dateTo !== null) return true;

    return false;
  };

  const loadNextPage = () => {
    if (page === totalPages) return;

    fetchMore({
      variables: {
        skip: (page + 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page + 1);
  };

  const loadPrevPage = () => {
    if (page === 1) return;

    fetchMore({
      variables: {
        skip: (page - 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page - 1);
  };

  // Use submittedParams here, not searchParams
  const { data, loading, error, fetchMore } = useEtlFilesQuery({
    variables: {
      where: {
        filter: { startsWith: submittedParams.dataset },
        from: { gte: submittedParams.dateFrom },
        to: { lte: submittedParams.dateTo },
      },
      skip: (page - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    },
    skip: hasParams(submittedParams) === false,
  });

  // Assuming data?.aggregateInvoice?._count?.number contains the total count of invoices
  const totalCount = data?.etlFiles[0]?.total || 0;

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / PAGE_SIZE);

  return (
    <Box sx={{ margin: 2, pt: 3, p: 1, minHeight: "80vh" }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Import: Files
      </Typography>
      <Divider sx={{ my: 2, bgcolor: "secondary.main" }} />

      <ETLJobSearchForm
        onSearch={handleSearch}
        onUploadComplete={handleUploadComplete}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        loading={loading}
        error={error}
      />

      {!loading && data && (
        <ETLFilesTable
          etlFilesData={data}
          handlePrevPage={loadPrevPage}
          handleNextPage={loadNextPage}
          currentPage={page}
          totalPages={totalPages}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default ETLFilesList;
export type { SearchParams };
