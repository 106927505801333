import { AuthProvider } from "react-oidc-context";

import * as React from 'react';
import './App.css';
import Login from './components/login/Login';
import { ThemeProvider } from '@mui/material/styles';
import theme from './Theme';
import en from 'date-fns/locale/en-US';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import ErrorBoundary from "./components/ErrorBoundary";
import { useAuthLink } from './components/login/UseAuthLink';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";

// WARN : Using webpack define plugin to inject env variable
const a: string = (process.env.REACT_APP_IDP as string);
const cid: string = (process.env.REACT_APP_CLIENT_ID as string);
const ruri: string = (process.env.REACT_APP_REDIRECT_URI as string);
const invoiceSvcLink = new HttpLink({ uri: process.env.REACT_APP_INVOICE_API_URL + "/graphql" });

const oidcConfig = {
  authority: a,
  client_id: cid,
  redirect_uri: ruri,
};

const router = createBrowserRouter(
  createRoutesFromElements(
    AppRoutes()
  )
)

const cache = new InMemoryCache({
  typePolicies: {
    IUser: {
      fields: {
        roles: {
          merge(existing, incoming) {
            // Merge logic: For this example, we prioritize the incoming data.
            return incoming;
          },
        },
      },
    },
  },
});

// Wrapper to inject access token into apollo requests
const ApolloWrapper: React.FC = () => {
  // Inject access token in all Apollo client headers
  const authLink = useAuthLink();
  const invoiceSvc = new ApolloClient({
    link: authLink.concat(invoiceSvcLink),
    cache: cache,
  });

  return (
    <ApolloProvider client={invoiceSvc}>
      <RouterProvider router={router} />
    </ApolloProvider>
  );
};


const App: React.FC = () => {

  return (

    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en} >
        <ErrorBoundary>
          <AuthProvider {...oidcConfig} loadUserInfo={true} >
            <Login>
              <ApolloWrapper />
            </Login>
          </AuthProvider>
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider >

  );
}

export default App;

