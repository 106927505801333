import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type AggregateCustomer = {
  __typename?: 'AggregateCustomer';
  _avg?: Maybe<CustomerAvgAggregate>;
  _count?: Maybe<CustomerCountAggregate>;
  _max?: Maybe<CustomerMaxAggregate>;
  _min?: Maybe<CustomerMinAggregate>;
  _sum?: Maybe<CustomerSumAggregate>;
};

export type AggregateEtlInvoiceJob = {
  __typename?: 'AggregateETLInvoiceJob';
  _avg?: Maybe<EtlInvoiceJobAvgAggregate>;
  _count?: Maybe<EtlInvoiceJobCountAggregate>;
  _max?: Maybe<EtlInvoiceJobMaxAggregate>;
  _min?: Maybe<EtlInvoiceJobMinAggregate>;
  _sum?: Maybe<EtlInvoiceJobSumAggregate>;
};

export type AggregateIUser = {
  __typename?: 'AggregateIUser';
  _avg?: Maybe<IUserAvgAggregate>;
  _count?: Maybe<IUserCountAggregate>;
  _max?: Maybe<IUserMaxAggregate>;
  _min?: Maybe<IUserMinAggregate>;
  _sum?: Maybe<IUserSumAggregate>;
};

export type AggregateInvoice = {
  __typename?: 'AggregateInvoice';
  _avg?: Maybe<InvoiceAvgAggregate>;
  _count?: Maybe<InvoiceCountAggregate>;
  _max?: Maybe<InvoiceMaxAggregate>;
  _min?: Maybe<InvoiceMinAggregate>;
  _sum?: Maybe<InvoiceSumAggregate>;
};

export type AggregateRole = {
  __typename?: 'AggregateRole';
  _avg?: Maybe<RoleAvgAggregate>;
  _count?: Maybe<RoleCountAggregate>;
  _max?: Maybe<RoleMaxAggregate>;
  _min?: Maybe<RoleMinAggregate>;
  _sum?: Maybe<RoleSumAggregate>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  city: Scalars['String']['output'];
  code: Scalars['String']['output'];
  country: Scalars['String']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  postal: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
};

export type BillingAddressCreateManyCustomerInput = {
  city: Scalars['String']['input'];
  code: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postal: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type BillingAddressCreateManyCustomerInputEnvelope = {
  data: Array<BillingAddressCreateManyCustomerInput>;
};

export type BillingAddressCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<BillingAddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BillingAddressCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<BillingAddressCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<BillingAddressCreateManyCustomerInputEnvelope>;
};

export type BillingAddressCreateOrConnectWithoutCustomerInput = {
  create: BillingAddressCreateWithoutCustomerInput;
  where: BillingAddressWhereUniqueInput;
};

export type BillingAddressCreateWithoutCustomerInput = {
  city: Scalars['String']['input'];
  code: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postal: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type BillingAddressListRelationFilter = {
  every?: InputMaybe<BillingAddressWhereInput>;
  none?: InputMaybe<BillingAddressWhereInput>;
  some?: InputMaybe<BillingAddressWhereInput>;
};

export type BillingAddressOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BillingAddressOrderByWithRelationInput = {
  city?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  postal?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
};

export enum BillingAddressScalarFieldEnum {
  City = 'city',
  Code = 'code',
  Country = 'country',
  CustomerId = 'customerId',
  Id = 'id',
  Name = 'name',
  Postal = 'postal',
  State = 'state',
  Street = 'street'
}

export type BillingAddressScalarWhereInput = {
  AND?: InputMaybe<Array<BillingAddressScalarWhereInput>>;
  NOT?: InputMaybe<Array<BillingAddressScalarWhereInput>>;
  OR?: InputMaybe<Array<BillingAddressScalarWhereInput>>;
  city?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  customerId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  postal?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
};

export type BillingAddressUpdateManyMutationInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  postal?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  street?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BillingAddressUpdateManyWithWhereWithoutCustomerInput = {
  data: BillingAddressUpdateManyMutationInput;
  where: BillingAddressScalarWhereInput;
};

export type BillingAddressUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<BillingAddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BillingAddressCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<BillingAddressCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<BillingAddressCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<BillingAddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BillingAddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BillingAddressWhereUniqueInput>>;
  set?: InputMaybe<Array<BillingAddressWhereUniqueInput>>;
  update?: InputMaybe<Array<BillingAddressUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<BillingAddressUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<BillingAddressUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type BillingAddressUpdateWithWhereUniqueWithoutCustomerInput = {
  data: BillingAddressUpdateWithoutCustomerInput;
  where: BillingAddressWhereUniqueInput;
};

export type BillingAddressUpdateWithoutCustomerInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  postal?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  street?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type BillingAddressUpsertWithWhereUniqueWithoutCustomerInput = {
  create: BillingAddressCreateWithoutCustomerInput;
  update: BillingAddressUpdateWithoutCustomerInput;
  where: BillingAddressWhereUniqueInput;
};

export type BillingAddressWhereInput = {
  AND?: InputMaybe<Array<BillingAddressWhereInput>>;
  NOT?: InputMaybe<Array<BillingAddressWhereInput>>;
  OR?: InputMaybe<Array<BillingAddressWhereInput>>;
  city?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  postal?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
};

export type BillingAddressWhereUniqueInput = {
  AND?: InputMaybe<Array<BillingAddressWhereInput>>;
  NOT?: InputMaybe<Array<BillingAddressWhereInput>>;
  OR?: InputMaybe<Array<BillingAddressWhereInput>>;
  city?: InputMaybe<StringFilter>;
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<StringFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringFilter>;
  postal?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type Customer = {
  __typename?: 'Customer';
  _count?: Maybe<CustomerCount>;
  addresses: Array<BillingAddress>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  dkNumber: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  invoices: Array<Invoice>;
  name: Scalars['String']['output'];
  paymentTerm: Scalars['String']['output'];
  postal: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  udids: Array<CustomerUdid>;
  updatedAt: Scalars['DateTimeISO']['output'];
  users: Array<IUser>;
};


export type CustomerAddressesArgs = {
  cursor?: InputMaybe<BillingAddressWhereUniqueInput>;
  distinct?: InputMaybe<Array<BillingAddressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BillingAddressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BillingAddressWhereInput>;
};


export type CustomerInvoicesArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type CustomerUdidsArgs = {
  cursor?: InputMaybe<CustomerUdidWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerUdidScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerUdidOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerUdidWhereInput>;
};


export type CustomerUsersArgs = {
  cursor?: InputMaybe<IUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<IUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IUserWhereInput>;
};

export type CustomerAvgAggregate = {
  __typename?: 'CustomerAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type CustomerCount = {
  __typename?: 'CustomerCount';
  addresses: Scalars['Int']['output'];
  invoices: Scalars['Int']['output'];
  udids: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
};


export type CustomerCountAddressesArgs = {
  where?: InputMaybe<BillingAddressWhereInput>;
};


export type CustomerCountInvoicesArgs = {
  where?: InputMaybe<InvoiceWhereInput>;
};


export type CustomerCountUdidsArgs = {
  where?: InputMaybe<CustomerUdidWhereInput>;
};


export type CustomerCountUsersArgs = {
  where?: InputMaybe<IUserWhereInput>;
};

export type CustomerCountAggregate = {
  __typename?: 'CustomerCountAggregate';
  _all: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  country: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  dkNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  paymentTerm: Scalars['Int']['output'];
  postal: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  street: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CustomerCreateInput = {
  addresses?: InputMaybe<BillingAddressCreateNestedManyWithoutCustomerInput>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dkNumber: Scalars['String']['input'];
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutCustomerInput>;
  name: Scalars['String']['input'];
  paymentTerm: Scalars['String']['input'];
  postal: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  udids?: InputMaybe<CustomerUdidCreateNestedManyWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  users?: InputMaybe<IUserCreateNestedManyWithoutCustomersInput>;
};

export type CustomerCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<CustomerCreateWithoutUsersInput>>;
};

export type CustomerCreateNestedOneWithoutInvoicesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutInvoicesInput>;
  create?: InputMaybe<CustomerCreateWithoutInvoicesInput>;
};

export type CustomerCreateNestedOneWithoutUdidsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutUdidsInput>;
  create?: InputMaybe<CustomerCreateWithoutUdidsInput>;
};

export type CustomerCreateOrConnectWithoutInvoicesInput = {
  create: CustomerCreateWithoutInvoicesInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutUdidsInput = {
  create: CustomerCreateWithoutUdidsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutUsersInput = {
  create: CustomerCreateWithoutUsersInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateWithoutInvoicesInput = {
  addresses?: InputMaybe<BillingAddressCreateNestedManyWithoutCustomerInput>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dkNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  paymentTerm: Scalars['String']['input'];
  postal: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  udids?: InputMaybe<CustomerUdidCreateNestedManyWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  users?: InputMaybe<IUserCreateNestedManyWithoutCustomersInput>;
};

export type CustomerCreateWithoutUdidsInput = {
  addresses?: InputMaybe<BillingAddressCreateNestedManyWithoutCustomerInput>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dkNumber: Scalars['String']['input'];
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutCustomerInput>;
  name: Scalars['String']['input'];
  paymentTerm: Scalars['String']['input'];
  postal: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  users?: InputMaybe<IUserCreateNestedManyWithoutCustomersInput>;
};

export type CustomerCreateWithoutUsersInput = {
  addresses?: InputMaybe<BillingAddressCreateNestedManyWithoutCustomerInput>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dkNumber: Scalars['String']['input'];
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutCustomerInput>;
  name: Scalars['String']['input'];
  paymentTerm: Scalars['String']['input'];
  postal: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  udids?: InputMaybe<CustomerUdidCreateNestedManyWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CustomerListRelationFilter = {
  every?: InputMaybe<CustomerWhereInput>;
  none?: InputMaybe<CustomerWhereInput>;
  some?: InputMaybe<CustomerWhereInput>;
};

export type CustomerMaxAggregate = {
  __typename?: 'CustomerMaxAggregate';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  dkNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentTerm?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CustomerMinAggregate = {
  __typename?: 'CustomerMinAggregate';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  dkNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentTerm?: Maybe<Scalars['String']['output']>;
  postal?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CustomerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CustomerOrderByWithRelationInput = {
  addresses?: InputMaybe<BillingAddressOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dkNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoices?: InputMaybe<InvoiceOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  paymentTerm?: InputMaybe<SortOrder>;
  postal?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  udids?: InputMaybe<CustomerUdidOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<IUserOrderByRelationAggregateInput>;
};

export type CustomerRelationFilter = {
  is?: InputMaybe<CustomerWhereInput>;
  isNot?: InputMaybe<CustomerWhereInput>;
};

export enum CustomerScalarFieldEnum {
  City = 'city',
  Country = 'country',
  CreatedAt = 'createdAt',
  DkNumber = 'dkNumber',
  Id = 'id',
  Name = 'name',
  PaymentTerm = 'paymentTerm',
  Postal = 'postal',
  State = 'state',
  Street = 'street',
  UpdatedAt = 'updatedAt'
}

export type CustomerScalarWhereInput = {
  AND?: InputMaybe<Array<CustomerScalarWhereInput>>;
  NOT?: InputMaybe<Array<CustomerScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomerScalarWhereInput>>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dkNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  paymentTerm?: InputMaybe<StringFilter>;
  postal?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerSumAggregate = {
  __typename?: 'CustomerSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type CustomerUdid = {
  __typename?: 'CustomerUDID';
  _count?: Maybe<CustomerUdidCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  customer: Customer;
  customerId: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  invoice: Array<CustomerUdidOnInvoices>;
  udid: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};


export type CustomerUdidInvoiceArgs = {
  cursor?: InputMaybe<CustomerUdidOnInvoicesWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerUdidOnInvoicesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerUdidOnInvoicesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerUdidOnInvoicesWhereInput>;
};

export type CustomerUdidCount = {
  __typename?: 'CustomerUDIDCount';
  invoice: Scalars['Int']['output'];
};


export type CustomerUdidCountInvoiceArgs = {
  where?: InputMaybe<CustomerUdidOnInvoicesWhereInput>;
};

export type CustomerUdidCreateManyCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName: Scalars['String']['input'];
  udid: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CustomerUdidCreateManyCustomerInputEnvelope = {
  data: Array<CustomerUdidCreateManyCustomerInput>;
};

export type CustomerUdidCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<CustomerUdidWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerUdidCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<CustomerUdidCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<CustomerUdidCreateManyCustomerInputEnvelope>;
};

export type CustomerUdidCreateNestedOneWithoutInvoiceInput = {
  connect?: InputMaybe<CustomerUdidWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerUdidCreateOrConnectWithoutInvoiceInput>;
  create?: InputMaybe<CustomerUdidCreateWithoutInvoiceInput>;
};

export type CustomerUdidCreateOrConnectWithoutCustomerInput = {
  create: CustomerUdidCreateWithoutCustomerInput;
  where: CustomerUdidWhereUniqueInput;
};

export type CustomerUdidCreateOrConnectWithoutInvoiceInput = {
  create: CustomerUdidCreateWithoutInvoiceInput;
  where: CustomerUdidWhereUniqueInput;
};

export type CustomerUdidCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  displayName: Scalars['String']['input'];
  invoice?: InputMaybe<CustomerUdidOnInvoicesCreateNestedManyWithoutCustomerUdidInput>;
  udid: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CustomerUdidCreateWithoutInvoiceInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customer: CustomerCreateNestedOneWithoutUdidsInput;
  displayName: Scalars['String']['input'];
  udid: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CustomerUdidListRelationFilter = {
  every?: InputMaybe<CustomerUdidWhereInput>;
  none?: InputMaybe<CustomerUdidWhereInput>;
  some?: InputMaybe<CustomerUdidWhereInput>;
};

export type CustomerUdidOnInvoices = {
  __typename?: 'CustomerUDIDOnInvoices';
  assignedAt: Scalars['DateTimeISO']['output'];
  customerUDID: CustomerUdid;
  customerUDIDId: Scalars['Int']['output'];
  invoice: Invoice;
  invoiceId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type CustomerUdidOnInvoicesCreateManyCustomerUdidInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  invoiceId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type CustomerUdidOnInvoicesCreateManyCustomerUdidInputEnvelope = {
  data: Array<CustomerUdidOnInvoicesCreateManyCustomerUdidInput>;
};

export type CustomerUdidOnInvoicesCreateManyInvoiceInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customerUDIDId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type CustomerUdidOnInvoicesCreateManyInvoiceInputEnvelope = {
  data: Array<CustomerUdidOnInvoicesCreateManyInvoiceInput>;
};

export type CustomerUdidOnInvoicesCreateNestedManyWithoutCustomerUdidInput = {
  connect?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerUdidOnInvoicesCreateOrConnectWithoutCustomerUdidInput>>;
  create?: InputMaybe<Array<CustomerUdidOnInvoicesCreateWithoutCustomerUdidInput>>;
  createMany?: InputMaybe<CustomerUdidOnInvoicesCreateManyCustomerUdidInputEnvelope>;
};

export type CustomerUdidOnInvoicesCreateNestedManyWithoutInvoiceInput = {
  connect?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerUdidOnInvoicesCreateOrConnectWithoutInvoiceInput>>;
  create?: InputMaybe<Array<CustomerUdidOnInvoicesCreateWithoutInvoiceInput>>;
  createMany?: InputMaybe<CustomerUdidOnInvoicesCreateManyInvoiceInputEnvelope>;
};

export type CustomerUdidOnInvoicesCreateOrConnectWithoutCustomerUdidInput = {
  create: CustomerUdidOnInvoicesCreateWithoutCustomerUdidInput;
  where: CustomerUdidOnInvoicesWhereUniqueInput;
};

export type CustomerUdidOnInvoicesCreateOrConnectWithoutInvoiceInput = {
  create: CustomerUdidOnInvoicesCreateWithoutInvoiceInput;
  where: CustomerUdidOnInvoicesWhereUniqueInput;
};

export type CustomerUdidOnInvoicesCreateWithoutCustomerUdidInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  invoice: InvoiceCreateNestedOneWithoutUdidsInput;
  value: Scalars['String']['input'];
};

export type CustomerUdidOnInvoicesCreateWithoutInvoiceInput = {
  assignedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customerUDID: CustomerUdidCreateNestedOneWithoutInvoiceInput;
  value: Scalars['String']['input'];
};

export type CustomerUdidOnInvoicesInvoiceIdCustomerUdidIdCompoundUniqueInput = {
  customerUDIDId: Scalars['Int']['input'];
  invoiceId: Scalars['Int']['input'];
};

export type CustomerUdidOnInvoicesListRelationFilter = {
  every?: InputMaybe<CustomerUdidOnInvoicesWhereInput>;
  none?: InputMaybe<CustomerUdidOnInvoicesWhereInput>;
  some?: InputMaybe<CustomerUdidOnInvoicesWhereInput>;
};

export type CustomerUdidOnInvoicesOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CustomerUdidOnInvoicesOrderByWithRelationInput = {
  assignedAt?: InputMaybe<SortOrder>;
  customerUDID?: InputMaybe<CustomerUdidOrderByWithRelationInput>;
  customerUDIDId?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<InvoiceOrderByWithRelationInput>;
  invoiceId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum CustomerUdidOnInvoicesScalarFieldEnum {
  AssignedAt = 'assignedAt',
  CustomerUdidId = 'customerUDIDId',
  InvoiceId = 'invoiceId',
  Value = 'value'
}

export type CustomerUdidOnInvoicesScalarWhereInput = {
  AND?: InputMaybe<Array<CustomerUdidOnInvoicesScalarWhereInput>>;
  NOT?: InputMaybe<Array<CustomerUdidOnInvoicesScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomerUdidOnInvoicesScalarWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  customerUDIDId?: InputMaybe<IntFilter>;
  invoiceId?: InputMaybe<IntFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CustomerUdidOnInvoicesUpdateManyMutationInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CustomerUdidOnInvoicesUpdateManyWithWhereWithoutCustomerUdidInput = {
  data: CustomerUdidOnInvoicesUpdateManyMutationInput;
  where: CustomerUdidOnInvoicesScalarWhereInput;
};

export type CustomerUdidOnInvoicesUpdateManyWithWhereWithoutInvoiceInput = {
  data: CustomerUdidOnInvoicesUpdateManyMutationInput;
  where: CustomerUdidOnInvoicesScalarWhereInput;
};

export type CustomerUdidOnInvoicesUpdateManyWithoutCustomerUdidNestedInput = {
  connect?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerUdidOnInvoicesCreateOrConnectWithoutCustomerUdidInput>>;
  create?: InputMaybe<Array<CustomerUdidOnInvoicesCreateWithoutCustomerUdidInput>>;
  createMany?: InputMaybe<CustomerUdidOnInvoicesCreateManyCustomerUdidInputEnvelope>;
  delete?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerUdidOnInvoicesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomerUdidOnInvoicesUpdateWithWhereUniqueWithoutCustomerUdidInput>>;
  updateMany?: InputMaybe<Array<CustomerUdidOnInvoicesUpdateManyWithWhereWithoutCustomerUdidInput>>;
  upsert?: InputMaybe<Array<CustomerUdidOnInvoicesUpsertWithWhereUniqueWithoutCustomerUdidInput>>;
};

export type CustomerUdidOnInvoicesUpdateManyWithoutInvoiceNestedInput = {
  connect?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerUdidOnInvoicesCreateOrConnectWithoutInvoiceInput>>;
  create?: InputMaybe<Array<CustomerUdidOnInvoicesCreateWithoutInvoiceInput>>;
  createMany?: InputMaybe<CustomerUdidOnInvoicesCreateManyInvoiceInputEnvelope>;
  delete?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerUdidOnInvoicesScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerUdidOnInvoicesWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomerUdidOnInvoicesUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: InputMaybe<Array<CustomerUdidOnInvoicesUpdateManyWithWhereWithoutInvoiceInput>>;
  upsert?: InputMaybe<Array<CustomerUdidOnInvoicesUpsertWithWhereUniqueWithoutInvoiceInput>>;
};

export type CustomerUdidOnInvoicesUpdateWithWhereUniqueWithoutCustomerUdidInput = {
  data: CustomerUdidOnInvoicesUpdateWithoutCustomerUdidInput;
  where: CustomerUdidOnInvoicesWhereUniqueInput;
};

export type CustomerUdidOnInvoicesUpdateWithWhereUniqueWithoutInvoiceInput = {
  data: CustomerUdidOnInvoicesUpdateWithoutInvoiceInput;
  where: CustomerUdidOnInvoicesWhereUniqueInput;
};

export type CustomerUdidOnInvoicesUpdateWithoutCustomerUdidInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  invoice?: InputMaybe<InvoiceUpdateOneRequiredWithoutUdidsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CustomerUdidOnInvoicesUpdateWithoutInvoiceInput = {
  assignedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerUDID?: InputMaybe<CustomerUdidUpdateOneRequiredWithoutInvoiceNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CustomerUdidOnInvoicesUpsertWithWhereUniqueWithoutCustomerUdidInput = {
  create: CustomerUdidOnInvoicesCreateWithoutCustomerUdidInput;
  update: CustomerUdidOnInvoicesUpdateWithoutCustomerUdidInput;
  where: CustomerUdidOnInvoicesWhereUniqueInput;
};

export type CustomerUdidOnInvoicesUpsertWithWhereUniqueWithoutInvoiceInput = {
  create: CustomerUdidOnInvoicesCreateWithoutInvoiceInput;
  update: CustomerUdidOnInvoicesUpdateWithoutInvoiceInput;
  where: CustomerUdidOnInvoicesWhereUniqueInput;
};

export type CustomerUdidOnInvoicesWhereInput = {
  AND?: InputMaybe<Array<CustomerUdidOnInvoicesWhereInput>>;
  NOT?: InputMaybe<Array<CustomerUdidOnInvoicesWhereInput>>;
  OR?: InputMaybe<Array<CustomerUdidOnInvoicesWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  customerUDID?: InputMaybe<CustomerUdidRelationFilter>;
  customerUDIDId?: InputMaybe<IntFilter>;
  invoice?: InputMaybe<InvoiceRelationFilter>;
  invoiceId?: InputMaybe<IntFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CustomerUdidOnInvoicesWhereUniqueInput = {
  AND?: InputMaybe<Array<CustomerUdidOnInvoicesWhereInput>>;
  NOT?: InputMaybe<Array<CustomerUdidOnInvoicesWhereInput>>;
  OR?: InputMaybe<Array<CustomerUdidOnInvoicesWhereInput>>;
  assignedAt?: InputMaybe<DateTimeFilter>;
  customerUDID?: InputMaybe<CustomerUdidRelationFilter>;
  customerUDIDId?: InputMaybe<IntFilter>;
  invoice?: InputMaybe<InvoiceRelationFilter>;
  invoiceId?: InputMaybe<IntFilter>;
  invoiceId_customerUDIDId?: InputMaybe<CustomerUdidOnInvoicesInvoiceIdCustomerUdidIdCompoundUniqueInput>;
  value?: InputMaybe<StringFilter>;
};

export type CustomerUdidOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CustomerUdidOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<CustomerUdidOnInvoicesOrderByRelationAggregateInput>;
  udid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerUdidRelationFilter = {
  is?: InputMaybe<CustomerUdidWhereInput>;
  isNot?: InputMaybe<CustomerUdidWhereInput>;
};

export enum CustomerUdidScalarFieldEnum {
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  DisplayName = 'displayName',
  Id = 'id',
  Udid = 'udid',
  UpdatedAt = 'updatedAt'
}

export type CustomerUdidScalarWhereInput = {
  AND?: InputMaybe<Array<CustomerUdidScalarWhereInput>>;
  NOT?: InputMaybe<Array<CustomerUdidScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomerUdidScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<IntFilter>;
  displayName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  udid?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerUdidUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  udid?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUdidUpdateManyWithWhereWithoutCustomerInput = {
  data: CustomerUdidUpdateManyMutationInput;
  where: CustomerUdidScalarWhereInput;
};

export type CustomerUdidUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<CustomerUdidWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerUdidCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<CustomerUdidCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<CustomerUdidCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<CustomerUdidWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerUdidScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerUdidWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerUdidWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomerUdidUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<CustomerUdidUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<CustomerUdidUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type CustomerUdidUpdateOneRequiredWithoutInvoiceNestedInput = {
  connect?: InputMaybe<CustomerUdidWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerUdidCreateOrConnectWithoutInvoiceInput>;
  create?: InputMaybe<CustomerUdidCreateWithoutInvoiceInput>;
  update?: InputMaybe<CustomerUdidUpdateToOneWithWhereWithoutInvoiceInput>;
  upsert?: InputMaybe<CustomerUdidUpsertWithoutInvoiceInput>;
};

export type CustomerUdidUpdateToOneWithWhereWithoutInvoiceInput = {
  data: CustomerUdidUpdateWithoutInvoiceInput;
  where?: InputMaybe<CustomerUdidWhereInput>;
};

export type CustomerUdidUpdateWithWhereUniqueWithoutCustomerInput = {
  data: CustomerUdidUpdateWithoutCustomerInput;
  where: CustomerUdidWhereUniqueInput;
};

export type CustomerUdidUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoice?: InputMaybe<CustomerUdidOnInvoicesUpdateManyWithoutCustomerUdidNestedInput>;
  udid?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUdidUpdateWithoutInvoiceInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutUdidsNestedInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  udid?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUdidUpsertWithWhereUniqueWithoutCustomerInput = {
  create: CustomerUdidCreateWithoutCustomerInput;
  update: CustomerUdidUpdateWithoutCustomerInput;
  where: CustomerUdidWhereUniqueInput;
};

export type CustomerUdidUpsertWithoutInvoiceInput = {
  create: CustomerUdidCreateWithoutInvoiceInput;
  update: CustomerUdidUpdateWithoutInvoiceInput;
  where?: InputMaybe<CustomerUdidWhereInput>;
};

export type CustomerUdidWhereInput = {
  AND?: InputMaybe<Array<CustomerUdidWhereInput>>;
  NOT?: InputMaybe<Array<CustomerUdidWhereInput>>;
  OR?: InputMaybe<Array<CustomerUdidWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<IntFilter>;
  displayName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  invoice?: InputMaybe<CustomerUdidOnInvoicesListRelationFilter>;
  udid?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerUdidWhereUniqueInput = {
  AND?: InputMaybe<Array<CustomerUdidWhereInput>>;
  NOT?: InputMaybe<Array<CustomerUdidWhereInput>>;
  OR?: InputMaybe<Array<CustomerUdidWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<IntFilter>;
  displayName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice?: InputMaybe<CustomerUdidOnInvoicesListRelationFilter>;
  udid?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerUpdateInput = {
  addresses?: InputMaybe<BillingAddressUpdateManyWithoutCustomerNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dkNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentTerm?: InputMaybe<StringFieldUpdateOperationsInput>;
  postal?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  street?: InputMaybe<StringFieldUpdateOperationsInput>;
  udids?: InputMaybe<CustomerUdidUpdateManyWithoutCustomerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<IUserUpdateManyWithoutCustomersNestedInput>;
};

export type CustomerUpdateManyMutationInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dkNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentTerm?: InputMaybe<StringFieldUpdateOperationsInput>;
  postal?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  street?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUpdateManyWithWhereWithoutUsersInput = {
  data: CustomerUpdateManyMutationInput;
  where: CustomerScalarWhereInput;
};

export type CustomerUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<CustomerCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomerUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<CustomerUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<CustomerUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type CustomerUpdateOneRequiredWithoutInvoicesNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutInvoicesInput>;
  create?: InputMaybe<CustomerCreateWithoutInvoicesInput>;
  update?: InputMaybe<CustomerUpdateToOneWithWhereWithoutInvoicesInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutInvoicesInput>;
};

export type CustomerUpdateOneRequiredWithoutUdidsNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutUdidsInput>;
  create?: InputMaybe<CustomerCreateWithoutUdidsInput>;
  update?: InputMaybe<CustomerUpdateToOneWithWhereWithoutUdidsInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutUdidsInput>;
};

export type CustomerUpdateToOneWithWhereWithoutInvoicesInput = {
  data: CustomerUpdateWithoutInvoicesInput;
  where?: InputMaybe<CustomerWhereInput>;
};

export type CustomerUpdateToOneWithWhereWithoutUdidsInput = {
  data: CustomerUpdateWithoutUdidsInput;
  where?: InputMaybe<CustomerWhereInput>;
};

export type CustomerUpdateWithWhereUniqueWithoutUsersInput = {
  data: CustomerUpdateWithoutUsersInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerUpdateWithoutInvoicesInput = {
  addresses?: InputMaybe<BillingAddressUpdateManyWithoutCustomerNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dkNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentTerm?: InputMaybe<StringFieldUpdateOperationsInput>;
  postal?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  street?: InputMaybe<StringFieldUpdateOperationsInput>;
  udids?: InputMaybe<CustomerUdidUpdateManyWithoutCustomerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<IUserUpdateManyWithoutCustomersNestedInput>;
};

export type CustomerUpdateWithoutUdidsInput = {
  addresses?: InputMaybe<BillingAddressUpdateManyWithoutCustomerNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dkNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentTerm?: InputMaybe<StringFieldUpdateOperationsInput>;
  postal?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  street?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<IUserUpdateManyWithoutCustomersNestedInput>;
};

export type CustomerUpdateWithoutUsersInput = {
  addresses?: InputMaybe<BillingAddressUpdateManyWithoutCustomerNestedInput>;
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dkNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutCustomerNestedInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  paymentTerm?: InputMaybe<StringFieldUpdateOperationsInput>;
  postal?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  street?: InputMaybe<StringFieldUpdateOperationsInput>;
  udids?: InputMaybe<CustomerUdidUpdateManyWithoutCustomerNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUpsertWithWhereUniqueWithoutUsersInput = {
  create: CustomerCreateWithoutUsersInput;
  update: CustomerUpdateWithoutUsersInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerUpsertWithoutInvoicesInput = {
  create: CustomerCreateWithoutInvoicesInput;
  update: CustomerUpdateWithoutInvoicesInput;
  where?: InputMaybe<CustomerWhereInput>;
};

export type CustomerUpsertWithoutUdidsInput = {
  create: CustomerCreateWithoutUdidsInput;
  update: CustomerUpdateWithoutUdidsInput;
  where?: InputMaybe<CustomerWhereInput>;
};

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  addresses?: InputMaybe<BillingAddressListRelationFilter>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dkNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  paymentTerm?: InputMaybe<StringFilter>;
  postal?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
  udids?: InputMaybe<CustomerUdidListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<IUserListRelationFilter>;
};

export type CustomerWhereUniqueInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  addresses?: InputMaybe<BillingAddressListRelationFilter>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dkNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  paymentTerm?: InputMaybe<StringFilter>;
  postal?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
  udids?: InputMaybe<CustomerUdidListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<IUserListRelationFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type EtlFile = {
  __typename?: 'ETLFile';
  ctime: Scalars['DateTimeISO']['output'];
  hash: Scalars['String']['output'];
  mtime: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type EtlFileWhereInput = {
  filter?: InputMaybe<StringNullableFilter>;
  from?: InputMaybe<DateTimeFilter>;
  to?: InputMaybe<DateTimeFilter>;
};

export type EtlInvoiceJob = {
  __typename?: 'ETLInvoiceJob';
  _count?: Maybe<EtlInvoiceJobCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  dataHash: Scalars['String']['output'];
  dataId: Scalars['String']['output'];
  emails?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  etlId: Scalars['String']['output'];
  from?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['Int']['output'];
  importedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  importedTimes?: Maybe<Scalars['Int']['output']>;
  invoices: Array<Invoice>;
  status: Scalars['String']['output'];
  to?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
};


export type EtlInvoiceJobInvoicesArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};

export type EtlInvoiceJobAvgAggregate = {
  __typename?: 'ETLInvoiceJobAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  importedTimes?: Maybe<Scalars['Float']['output']>;
};

export type EtlInvoiceJobCount = {
  __typename?: 'ETLInvoiceJobCount';
  invoices: Scalars['Int']['output'];
};


export type EtlInvoiceJobCountInvoicesArgs = {
  where?: InputMaybe<InvoiceWhereInput>;
};

export type EtlInvoiceJobCountAggregate = {
  __typename?: 'ETLInvoiceJobCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  dataHash: Scalars['Int']['output'];
  dataId: Scalars['Int']['output'];
  emails: Scalars['Int']['output'];
  errors: Scalars['Int']['output'];
  etlId: Scalars['Int']['output'];
  from: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  importedAt: Scalars['Int']['output'];
  importedTimes: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type EtlInvoiceJobCreateNestedOneWithoutInvoicesInput = {
  connect?: InputMaybe<EtlInvoiceJobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EtlInvoiceJobCreateOrConnectWithoutInvoicesInput>;
  create?: InputMaybe<EtlInvoiceJobCreateWithoutInvoicesInput>;
};

export type EtlInvoiceJobCreateOrConnectWithoutInvoicesInput = {
  create: EtlInvoiceJobCreateWithoutInvoicesInput;
  where: EtlInvoiceJobWhereUniqueInput;
};

export type EtlInvoiceJobCreateWithoutInvoicesInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dataHash: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  emails?: InputMaybe<Scalars['String']['input']>;
  errors?: InputMaybe<Scalars['String']['input']>;
  etlId: Scalars['String']['input'];
  from?: InputMaybe<Scalars['DateTimeISO']['input']>;
  importedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  importedTimes?: InputMaybe<Scalars['Int']['input']>;
  status: Scalars['String']['input'];
  to?: InputMaybe<Scalars['DateTimeISO']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type EtlInvoiceJobMaxAggregate = {
  __typename?: 'ETLInvoiceJobMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  dataHash?: Maybe<Scalars['String']['output']>;
  dataId?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  etlId?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  importedTimes?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type EtlInvoiceJobMinAggregate = {
  __typename?: 'ETLInvoiceJobMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  dataHash?: Maybe<Scalars['String']['output']>;
  dataId?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  etlId?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  importedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  importedTimes?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type EtlInvoiceJobOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  dataHash?: InputMaybe<SortOrder>;
  dataId?: InputMaybe<SortOrder>;
  emails?: InputMaybe<SortOrderInput>;
  errors?: InputMaybe<SortOrderInput>;
  etlId?: InputMaybe<SortOrder>;
  from?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  importedAt?: InputMaybe<SortOrderInput>;
  importedTimes?: InputMaybe<SortOrderInput>;
  invoices?: InputMaybe<InvoiceOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  to?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EtlInvoiceJobRelationFilter = {
  is?: InputMaybe<EtlInvoiceJobWhereInput>;
  isNot?: InputMaybe<EtlInvoiceJobWhereInput>;
};

export type EtlInvoiceJobRunInput = {
  dataId: Scalars['String']['input'];
};

export enum EtlInvoiceJobScalarFieldEnum {
  CreatedAt = 'createdAt',
  DataHash = 'dataHash',
  DataId = 'dataId',
  Emails = 'emails',
  Errors = 'errors',
  EtlId = 'etlId',
  From = 'from',
  Id = 'id',
  ImportedAt = 'importedAt',
  ImportedTimes = 'importedTimes',
  Status = 'status',
  To = 'to',
  UpdatedAt = 'updatedAt'
}

export type EtlInvoiceJobSumAggregate = {
  __typename?: 'ETLInvoiceJobSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  importedTimes?: Maybe<Scalars['Int']['output']>;
};

export type EtlInvoiceJobUpdateOneRequiredWithoutInvoicesNestedInput = {
  connect?: InputMaybe<EtlInvoiceJobWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EtlInvoiceJobCreateOrConnectWithoutInvoicesInput>;
  create?: InputMaybe<EtlInvoiceJobCreateWithoutInvoicesInput>;
  update?: InputMaybe<EtlInvoiceJobUpdateToOneWithWhereWithoutInvoicesInput>;
  upsert?: InputMaybe<EtlInvoiceJobUpsertWithoutInvoicesInput>;
};

export type EtlInvoiceJobUpdateToOneWithWhereWithoutInvoicesInput = {
  data: EtlInvoiceJobUpdateWithoutInvoicesInput;
  where?: InputMaybe<EtlInvoiceJobWhereInput>;
};

export type EtlInvoiceJobUpdateWithoutInvoicesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataHash?: InputMaybe<StringFieldUpdateOperationsInput>;
  dataId?: InputMaybe<StringFieldUpdateOperationsInput>;
  emails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  errors?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  etlId?: InputMaybe<StringFieldUpdateOperationsInput>;
  from?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  importedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  importedTimes?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
  to?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EtlInvoiceJobUpsertWithoutInvoicesInput = {
  create: EtlInvoiceJobCreateWithoutInvoicesInput;
  update: EtlInvoiceJobUpdateWithoutInvoicesInput;
  where?: InputMaybe<EtlInvoiceJobWhereInput>;
};

export type EtlInvoiceJobWhereInput = {
  AND?: InputMaybe<Array<EtlInvoiceJobWhereInput>>;
  NOT?: InputMaybe<Array<EtlInvoiceJobWhereInput>>;
  OR?: InputMaybe<Array<EtlInvoiceJobWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataHash?: InputMaybe<StringFilter>;
  dataId?: InputMaybe<StringFilter>;
  emails?: InputMaybe<StringNullableFilter>;
  errors?: InputMaybe<StringNullableFilter>;
  etlId?: InputMaybe<StringFilter>;
  from?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  importedAt?: InputMaybe<DateTimeNullableFilter>;
  importedTimes?: InputMaybe<IntNullableFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  status?: InputMaybe<StringFilter>;
  to?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EtlInvoiceJobWhereUniqueInput = {
  AND?: InputMaybe<Array<EtlInvoiceJobWhereInput>>;
  NOT?: InputMaybe<Array<EtlInvoiceJobWhereInput>>;
  OR?: InputMaybe<Array<EtlInvoiceJobWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataHash?: InputMaybe<Scalars['String']['input']>;
  dataId?: InputMaybe<StringFilter>;
  emails?: InputMaybe<StringNullableFilter>;
  errors?: InputMaybe<StringNullableFilter>;
  etlId?: InputMaybe<StringFilter>;
  from?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  importedAt?: InputMaybe<DateTimeNullableFilter>;
  importedTimes?: InputMaybe<IntNullableFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  status?: InputMaybe<StringFilter>;
  to?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type IUser = {
  __typename?: 'IUser';
  _count?: Maybe<IUserCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  customers: Array<Customer>;
  email: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  roles: Array<Role>;
  salt: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};


export type IUserCustomersArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type IUserRolesArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoleWhereInput>;
};

export type IUserAvgAggregate = {
  __typename?: 'IUserAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type IUserCount = {
  __typename?: 'IUserCount';
  customers: Scalars['Int']['output'];
  roles: Scalars['Int']['output'];
};


export type IUserCountCustomersArgs = {
  where?: InputMaybe<CustomerWhereInput>;
};


export type IUserCountRolesArgs = {
  where?: InputMaybe<RoleWhereInput>;
};

export type IUserCountAggregate = {
  __typename?: 'IUserCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  enabled: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  password: Scalars['Int']['output'];
  salt: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type IUserCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutUsersInput>;
  email: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  salt: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type IUserCreateNestedManyWithoutCustomersInput = {
  connect?: InputMaybe<Array<IUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IUserCreateOrConnectWithoutCustomersInput>>;
  create?: InputMaybe<Array<IUserCreateWithoutCustomersInput>>;
};

export type IUserCreateOrConnectWithoutCustomersInput = {
  create: IUserCreateWithoutCustomersInput;
  where: IUserWhereUniqueInput;
};

export type IUserCreateWithoutCustomersInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  salt: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type IUserListRelationFilter = {
  every?: InputMaybe<IUserWhereInput>;
  none?: InputMaybe<IUserWhereInput>;
  some?: InputMaybe<IUserWhereInput>;
};

export type IUserMaxAggregate = {
  __typename?: 'IUserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  salt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type IUserMinAggregate = {
  __typename?: 'IUserMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  salt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type IUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type IUserOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  customers?: InputMaybe<CustomerOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  roles?: InputMaybe<RoleOrderByRelationAggregateInput>;
  salt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum IUserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  Enabled = 'enabled',
  Id = 'id',
  LastName = 'lastName',
  Name = 'name',
  Password = 'password',
  Salt = 'salt',
  UpdatedAt = 'updatedAt'
}

export type IUserScalarWhereInput = {
  AND?: InputMaybe<Array<IUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<IUserScalarWhereInput>>;
  OR?: InputMaybe<Array<IUserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  salt?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type IUserSumAggregate = {
  __typename?: 'IUserSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type IUserUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutUsersNestedInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersNestedInput>;
  salt?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IUserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  salt?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IUserUpdateManyWithWhereWithoutCustomersInput = {
  data: IUserUpdateManyMutationInput;
  where: IUserScalarWhereInput;
};

export type IUserUpdateManyWithoutCustomersNestedInput = {
  connect?: InputMaybe<Array<IUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IUserCreateOrConnectWithoutCustomersInput>>;
  create?: InputMaybe<Array<IUserCreateWithoutCustomersInput>>;
  delete?: InputMaybe<Array<IUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IUserWhereUniqueInput>>;
  set?: InputMaybe<Array<IUserWhereUniqueInput>>;
  update?: InputMaybe<Array<IUserUpdateWithWhereUniqueWithoutCustomersInput>>;
  updateMany?: InputMaybe<Array<IUserUpdateManyWithWhereWithoutCustomersInput>>;
  upsert?: InputMaybe<Array<IUserUpsertWithWhereUniqueWithoutCustomersInput>>;
};

export type IUserUpdateWithWhereUniqueWithoutCustomersInput = {
  data: IUserUpdateWithoutCustomersInput;
  where: IUserWhereUniqueInput;
};

export type IUserUpdateWithoutCustomersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  enabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersNestedInput>;
  salt?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type IUserUpsertWithWhereUniqueWithoutCustomersInput = {
  create: IUserCreateWithoutCustomersInput;
  update: IUserUpdateWithoutCustomersInput;
  where: IUserWhereUniqueInput;
};

export type IUserWhereInput = {
  AND?: InputMaybe<Array<IUserWhereInput>>;
  NOT?: InputMaybe<Array<IUserWhereInput>>;
  OR?: InputMaybe<Array<IUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customers?: InputMaybe<CustomerListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  roles?: InputMaybe<RoleListRelationFilter>;
  salt?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type IUserWhereUniqueInput = {
  AND?: InputMaybe<Array<IUserWhereInput>>;
  NOT?: InputMaybe<Array<IUserWhereInput>>;
  OR?: InputMaybe<Array<IUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customers?: InputMaybe<CustomerListRelationFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  roles?: InputMaybe<RoleListRelationFilter>;
  salt?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  _count?: Maybe<InvoiceCount>;
  billingAddressCode: Scalars['String']['output'];
  confirmation: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  currency: Scalars['String']['output'];
  customer: Customer;
  customerId: Scalars['Int']['output'];
  dkNumber: Scalars['String']['output'];
  etlHash?: Maybe<Scalars['String']['output']>;
  etlJob: EtlInvoiceJob;
  etlJobId: Scalars['Int']['output'];
  gst: Scalars['Float']['output'];
  hotelAddress?: Maybe<Scalars['String']['output']>;
  hotelFees: Scalars['Float']['output'];
  hst: Scalars['Float']['output'];
  lines: Array<InvoiceItem>;
  number: Scalars['Int']['output'];
  paymentMethod: Scalars['String']['output'];
  posted: Scalars['DateTimeISO']['output'];
  printedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  pst: Scalars['Float']['output'];
  qst: Scalars['Float']['output'];
  recordLocator: Scalars['String']['output'];
  serviceFees: Scalars['Float']['output'];
  serviceFeesTaxes: Scalars['Float']['output'];
  state: Scalars['String']['output'];
  subtotal: Scalars['Float']['output'];
  supplier: Scalars['String']['output'];
  total: Scalars['Float']['output'];
  udids: Array<CustomerUdidOnInvoices>;
  updatedAt: Scalars['DateTimeISO']['output'];
};


export type InvoiceLinesArgs = {
  cursor?: InputMaybe<InvoiceItemWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceItemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceItemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceItemWhereInput>;
};


export type InvoiceUdidsArgs = {
  cursor?: InputMaybe<CustomerUdidOnInvoicesWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerUdidOnInvoicesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerUdidOnInvoicesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerUdidOnInvoicesWhereInput>;
};

export type InvoiceAvgAggregate = {
  __typename?: 'InvoiceAvgAggregate';
  customerId?: Maybe<Scalars['Float']['output']>;
  etlJobId?: Maybe<Scalars['Float']['output']>;
  gst?: Maybe<Scalars['Float']['output']>;
  hotelFees?: Maybe<Scalars['Float']['output']>;
  hst?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  pst?: Maybe<Scalars['Float']['output']>;
  qst?: Maybe<Scalars['Float']['output']>;
  serviceFees?: Maybe<Scalars['Float']['output']>;
  serviceFeesTaxes?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceCount = {
  __typename?: 'InvoiceCount';
  lines: Scalars['Int']['output'];
  udids: Scalars['Int']['output'];
};


export type InvoiceCountLinesArgs = {
  where?: InputMaybe<InvoiceItemWhereInput>;
};


export type InvoiceCountUdidsArgs = {
  where?: InputMaybe<CustomerUdidOnInvoicesWhereInput>;
};

export type InvoiceCountAggregate = {
  __typename?: 'InvoiceCountAggregate';
  _all: Scalars['Int']['output'];
  billingAddressCode: Scalars['Int']['output'];
  confirmation: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  currency: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  dkNumber: Scalars['Int']['output'];
  etlHash: Scalars['Int']['output'];
  etlJobId: Scalars['Int']['output'];
  gst: Scalars['Int']['output'];
  hotelAddress: Scalars['Int']['output'];
  hotelFees: Scalars['Int']['output'];
  hst: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  paymentMethod: Scalars['Int']['output'];
  posted: Scalars['Int']['output'];
  printedAt: Scalars['Int']['output'];
  pst: Scalars['Int']['output'];
  qst: Scalars['Int']['output'];
  recordLocator: Scalars['Int']['output'];
  serviceFees: Scalars['Int']['output'];
  serviceFeesTaxes: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  subtotal: Scalars['Int']['output'];
  supplier: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type InvoiceCreateManyCustomerInput = {
  billingAddressCode?: InputMaybe<Scalars['String']['input']>;
  confirmation: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency: Scalars['String']['input'];
  dkNumber: Scalars['String']['input'];
  etlHash?: InputMaybe<Scalars['String']['input']>;
  etlJobId: Scalars['Int']['input'];
  gst?: InputMaybe<Scalars['Float']['input']>;
  hotelAddress?: InputMaybe<Scalars['String']['input']>;
  hotelFees?: InputMaybe<Scalars['Float']['input']>;
  hst?: InputMaybe<Scalars['Float']['input']>;
  paymentMethod: Scalars['String']['input'];
  posted: Scalars['DateTimeISO']['input'];
  printedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  pst?: InputMaybe<Scalars['Float']['input']>;
  qst?: InputMaybe<Scalars['Float']['input']>;
  recordLocator: Scalars['String']['input'];
  serviceFees?: InputMaybe<Scalars['Float']['input']>;
  serviceFeesTaxes?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  supplier: Scalars['String']['input'];
  total: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type InvoiceCreateManyCustomerInputEnvelope = {
  data: Array<InvoiceCreateManyCustomerInput>;
};

export type InvoiceCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<InvoiceCreateManyCustomerInputEnvelope>;
};

export type InvoiceCreateNestedOneWithoutUdidsInput = {
  connect?: InputMaybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InvoiceCreateOrConnectWithoutUdidsInput>;
  create?: InputMaybe<InvoiceCreateWithoutUdidsInput>;
};

export type InvoiceCreateOrConnectWithoutCustomerInput = {
  create: InvoiceCreateWithoutCustomerInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutUdidsInput = {
  create: InvoiceCreateWithoutUdidsInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateWithoutCustomerInput = {
  billingAddressCode?: InputMaybe<Scalars['String']['input']>;
  confirmation: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency: Scalars['String']['input'];
  dkNumber: Scalars['String']['input'];
  etlHash?: InputMaybe<Scalars['String']['input']>;
  etlJob: EtlInvoiceJobCreateNestedOneWithoutInvoicesInput;
  gst?: InputMaybe<Scalars['Float']['input']>;
  hotelAddress?: InputMaybe<Scalars['String']['input']>;
  hotelFees?: InputMaybe<Scalars['Float']['input']>;
  hst?: InputMaybe<Scalars['Float']['input']>;
  lines?: InputMaybe<InvoiceItemCreateNestedManyWithoutInvoiceInput>;
  paymentMethod: Scalars['String']['input'];
  posted: Scalars['DateTimeISO']['input'];
  printedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  pst?: InputMaybe<Scalars['Float']['input']>;
  qst?: InputMaybe<Scalars['Float']['input']>;
  recordLocator: Scalars['String']['input'];
  serviceFees?: InputMaybe<Scalars['Float']['input']>;
  serviceFeesTaxes?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  supplier: Scalars['String']['input'];
  total: Scalars['Float']['input'];
  udids?: InputMaybe<CustomerUdidOnInvoicesCreateNestedManyWithoutInvoiceInput>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type InvoiceCreateWithoutUdidsInput = {
  billingAddressCode?: InputMaybe<Scalars['String']['input']>;
  confirmation: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  currency: Scalars['String']['input'];
  customer: CustomerCreateNestedOneWithoutInvoicesInput;
  dkNumber: Scalars['String']['input'];
  etlHash?: InputMaybe<Scalars['String']['input']>;
  etlJob: EtlInvoiceJobCreateNestedOneWithoutInvoicesInput;
  gst?: InputMaybe<Scalars['Float']['input']>;
  hotelAddress?: InputMaybe<Scalars['String']['input']>;
  hotelFees?: InputMaybe<Scalars['Float']['input']>;
  hst?: InputMaybe<Scalars['Float']['input']>;
  lines?: InputMaybe<InvoiceItemCreateNestedManyWithoutInvoiceInput>;
  paymentMethod: Scalars['String']['input'];
  posted: Scalars['DateTimeISO']['input'];
  printedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  pst?: InputMaybe<Scalars['Float']['input']>;
  qst?: InputMaybe<Scalars['Float']['input']>;
  recordLocator: Scalars['String']['input'];
  serviceFees?: InputMaybe<Scalars['Float']['input']>;
  serviceFeesTaxes?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  supplier: Scalars['String']['input'];
  total: Scalars['Float']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  bookedNights: Scalars['Int']['output'];
  checkIn: Scalars['DateTimeISO']['output'];
  checkOut: Scalars['DateTimeISO']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  dailyRate: Scalars['Float']['output'];
  gst: Scalars['Float']['output'];
  hotelFees: Scalars['Float']['output'];
  hst: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  invoiceId: Scalars['Int']['output'];
  pst: Scalars['Float']['output'];
  qst: Scalars['Float']['output'];
  roomType: Scalars['String']['output'];
  serviceFees: Scalars['Float']['output'];
  serviceFeesTaxes: Scalars['Float']['output'];
  subtotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  traveler: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type InvoiceItemCreateManyInvoiceInput = {
  bookedNights: Scalars['Int']['input'];
  checkIn: Scalars['DateTimeISO']['input'];
  checkOut: Scalars['DateTimeISO']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dailyRate?: InputMaybe<Scalars['Float']['input']>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  hotelFees?: InputMaybe<Scalars['Float']['input']>;
  hst?: InputMaybe<Scalars['Float']['input']>;
  pst?: InputMaybe<Scalars['Float']['input']>;
  qst?: InputMaybe<Scalars['Float']['input']>;
  roomType: Scalars['String']['input'];
  serviceFees?: InputMaybe<Scalars['Float']['input']>;
  serviceFeesTaxes?: InputMaybe<Scalars['Float']['input']>;
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
  traveler: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type InvoiceItemCreateManyInvoiceInputEnvelope = {
  data: Array<InvoiceItemCreateManyInvoiceInput>;
};

export type InvoiceItemCreateNestedManyWithoutInvoiceInput = {
  connect?: InputMaybe<Array<InvoiceItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceItemCreateOrConnectWithoutInvoiceInput>>;
  create?: InputMaybe<Array<InvoiceItemCreateWithoutInvoiceInput>>;
  createMany?: InputMaybe<InvoiceItemCreateManyInvoiceInputEnvelope>;
};

export type InvoiceItemCreateOrConnectWithoutInvoiceInput = {
  create: InvoiceItemCreateWithoutInvoiceInput;
  where: InvoiceItemWhereUniqueInput;
};

export type InvoiceItemCreateWithoutInvoiceInput = {
  bookedNights: Scalars['Int']['input'];
  checkIn: Scalars['DateTimeISO']['input'];
  checkOut: Scalars['DateTimeISO']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dailyRate?: InputMaybe<Scalars['Float']['input']>;
  gst?: InputMaybe<Scalars['Float']['input']>;
  hotelFees?: InputMaybe<Scalars['Float']['input']>;
  hst?: InputMaybe<Scalars['Float']['input']>;
  pst?: InputMaybe<Scalars['Float']['input']>;
  qst?: InputMaybe<Scalars['Float']['input']>;
  roomType: Scalars['String']['input'];
  serviceFees?: InputMaybe<Scalars['Float']['input']>;
  serviceFeesTaxes?: InputMaybe<Scalars['Float']['input']>;
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
  traveler: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type InvoiceItemListRelationFilter = {
  every?: InputMaybe<InvoiceItemWhereInput>;
  none?: InputMaybe<InvoiceItemWhereInput>;
  some?: InputMaybe<InvoiceItemWhereInput>;
};

export type InvoiceItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InvoiceItemOrderByWithRelationInput = {
  bookedNights?: InputMaybe<SortOrder>;
  checkIn?: InputMaybe<SortOrder>;
  checkOut?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dailyRate?: InputMaybe<SortOrder>;
  gst?: InputMaybe<SortOrder>;
  hotelFees?: InputMaybe<SortOrder>;
  hst?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<InvoiceOrderByWithRelationInput>;
  invoiceId?: InputMaybe<SortOrder>;
  pst?: InputMaybe<SortOrder>;
  qst?: InputMaybe<SortOrder>;
  roomType?: InputMaybe<SortOrder>;
  serviceFees?: InputMaybe<SortOrder>;
  serviceFeesTaxes?: InputMaybe<SortOrder>;
  subtotal?: InputMaybe<SortOrder>;
  total?: InputMaybe<SortOrder>;
  traveler?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum InvoiceItemScalarFieldEnum {
  BookedNights = 'bookedNights',
  CheckIn = 'checkIn',
  CheckOut = 'checkOut',
  CreatedAt = 'createdAt',
  DailyRate = 'dailyRate',
  Gst = 'gst',
  HotelFees = 'hotelFees',
  Hst = 'hst',
  Id = 'id',
  InvoiceId = 'invoiceId',
  Pst = 'pst',
  Qst = 'qst',
  RoomType = 'roomType',
  ServiceFees = 'serviceFees',
  ServiceFeesTaxes = 'serviceFeesTaxes',
  Subtotal = 'subtotal',
  Total = 'total',
  Traveler = 'traveler',
  UpdatedAt = 'updatedAt'
}

export type InvoiceItemScalarWhereInput = {
  AND?: InputMaybe<Array<InvoiceItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceItemScalarWhereInput>>;
  OR?: InputMaybe<Array<InvoiceItemScalarWhereInput>>;
  bookedNights?: InputMaybe<IntFilter>;
  checkIn?: InputMaybe<DateTimeFilter>;
  checkOut?: InputMaybe<DateTimeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dailyRate?: InputMaybe<FloatFilter>;
  gst?: InputMaybe<FloatFilter>;
  hotelFees?: InputMaybe<FloatFilter>;
  hst?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  invoiceId?: InputMaybe<IntFilter>;
  pst?: InputMaybe<FloatFilter>;
  qst?: InputMaybe<FloatFilter>;
  roomType?: InputMaybe<StringFilter>;
  serviceFees?: InputMaybe<FloatFilter>;
  serviceFeesTaxes?: InputMaybe<FloatFilter>;
  subtotal?: InputMaybe<FloatFilter>;
  total?: InputMaybe<FloatFilter>;
  traveler?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InvoiceItemUpdateManyMutationInput = {
  bookedNights?: InputMaybe<IntFieldUpdateOperationsInput>;
  checkIn?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  checkOut?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dailyRate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  gst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hotelFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  pst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  roomType?: InputMaybe<StringFieldUpdateOperationsInput>;
  serviceFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  serviceFeesTaxes?: InputMaybe<FloatFieldUpdateOperationsInput>;
  subtotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  total?: InputMaybe<FloatFieldUpdateOperationsInput>;
  traveler?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvoiceItemUpdateManyWithWhereWithoutInvoiceInput = {
  data: InvoiceItemUpdateManyMutationInput;
  where: InvoiceItemScalarWhereInput;
};

export type InvoiceItemUpdateManyWithoutInvoiceNestedInput = {
  connect?: InputMaybe<Array<InvoiceItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceItemCreateOrConnectWithoutInvoiceInput>>;
  create?: InputMaybe<Array<InvoiceItemCreateWithoutInvoiceInput>>;
  createMany?: InputMaybe<InvoiceItemCreateManyInvoiceInputEnvelope>;
  delete?: InputMaybe<Array<InvoiceItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InvoiceItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InvoiceItemWhereUniqueInput>>;
  set?: InputMaybe<Array<InvoiceItemWhereUniqueInput>>;
  update?: InputMaybe<Array<InvoiceItemUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: InputMaybe<Array<InvoiceItemUpdateManyWithWhereWithoutInvoiceInput>>;
  upsert?: InputMaybe<Array<InvoiceItemUpsertWithWhereUniqueWithoutInvoiceInput>>;
};

export type InvoiceItemUpdateWithWhereUniqueWithoutInvoiceInput = {
  data: InvoiceItemUpdateWithoutInvoiceInput;
  where: InvoiceItemWhereUniqueInput;
};

export type InvoiceItemUpdateWithoutInvoiceInput = {
  bookedNights?: InputMaybe<IntFieldUpdateOperationsInput>;
  checkIn?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  checkOut?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dailyRate?: InputMaybe<FloatFieldUpdateOperationsInput>;
  gst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hotelFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  pst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  roomType?: InputMaybe<StringFieldUpdateOperationsInput>;
  serviceFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  serviceFeesTaxes?: InputMaybe<FloatFieldUpdateOperationsInput>;
  subtotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  total?: InputMaybe<FloatFieldUpdateOperationsInput>;
  traveler?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvoiceItemUpsertWithWhereUniqueWithoutInvoiceInput = {
  create: InvoiceItemCreateWithoutInvoiceInput;
  update: InvoiceItemUpdateWithoutInvoiceInput;
  where: InvoiceItemWhereUniqueInput;
};

export type InvoiceItemWhereInput = {
  AND?: InputMaybe<Array<InvoiceItemWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceItemWhereInput>>;
  OR?: InputMaybe<Array<InvoiceItemWhereInput>>;
  bookedNights?: InputMaybe<IntFilter>;
  checkIn?: InputMaybe<DateTimeFilter>;
  checkOut?: InputMaybe<DateTimeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dailyRate?: InputMaybe<FloatFilter>;
  gst?: InputMaybe<FloatFilter>;
  hotelFees?: InputMaybe<FloatFilter>;
  hst?: InputMaybe<FloatFilter>;
  id?: InputMaybe<IntFilter>;
  invoice?: InputMaybe<InvoiceRelationFilter>;
  invoiceId?: InputMaybe<IntFilter>;
  pst?: InputMaybe<FloatFilter>;
  qst?: InputMaybe<FloatFilter>;
  roomType?: InputMaybe<StringFilter>;
  serviceFees?: InputMaybe<FloatFilter>;
  serviceFeesTaxes?: InputMaybe<FloatFilter>;
  subtotal?: InputMaybe<FloatFilter>;
  total?: InputMaybe<FloatFilter>;
  traveler?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InvoiceItemWhereUniqueInput = {
  AND?: InputMaybe<Array<InvoiceItemWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceItemWhereInput>>;
  OR?: InputMaybe<Array<InvoiceItemWhereInput>>;
  bookedNights?: InputMaybe<IntFilter>;
  checkIn?: InputMaybe<DateTimeFilter>;
  checkOut?: InputMaybe<DateTimeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dailyRate?: InputMaybe<FloatFilter>;
  gst?: InputMaybe<FloatFilter>;
  hotelFees?: InputMaybe<FloatFilter>;
  hst?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invoice?: InputMaybe<InvoiceRelationFilter>;
  invoiceId?: InputMaybe<IntFilter>;
  pst?: InputMaybe<FloatFilter>;
  qst?: InputMaybe<FloatFilter>;
  roomType?: InputMaybe<StringFilter>;
  serviceFees?: InputMaybe<FloatFilter>;
  serviceFeesTaxes?: InputMaybe<FloatFilter>;
  subtotal?: InputMaybe<FloatFilter>;
  total?: InputMaybe<FloatFilter>;
  traveler?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InvoiceListRelationFilter = {
  every?: InputMaybe<InvoiceWhereInput>;
  none?: InputMaybe<InvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceMaxAggregate = {
  __typename?: 'InvoiceMaxAggregate';
  billingAddressCode?: Maybe<Scalars['String']['output']>;
  confirmation?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  dkNumber?: Maybe<Scalars['String']['output']>;
  etlHash?: Maybe<Scalars['String']['output']>;
  etlJobId?: Maybe<Scalars['Int']['output']>;
  gst?: Maybe<Scalars['Float']['output']>;
  hotelAddress?: Maybe<Scalars['String']['output']>;
  hotelFees?: Maybe<Scalars['Float']['output']>;
  hst?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  posted?: Maybe<Scalars['DateTimeISO']['output']>;
  printedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  pst?: Maybe<Scalars['Float']['output']>;
  qst?: Maybe<Scalars['Float']['output']>;
  recordLocator?: Maybe<Scalars['String']['output']>;
  serviceFees?: Maybe<Scalars['Float']['output']>;
  serviceFeesTaxes?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type InvoiceMinAggregate = {
  __typename?: 'InvoiceMinAggregate';
  billingAddressCode?: Maybe<Scalars['String']['output']>;
  confirmation?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  dkNumber?: Maybe<Scalars['String']['output']>;
  etlHash?: Maybe<Scalars['String']['output']>;
  etlJobId?: Maybe<Scalars['Int']['output']>;
  gst?: Maybe<Scalars['Float']['output']>;
  hotelAddress?: Maybe<Scalars['String']['output']>;
  hotelFees?: Maybe<Scalars['Float']['output']>;
  hst?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  posted?: Maybe<Scalars['DateTimeISO']['output']>;
  printedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  pst?: Maybe<Scalars['Float']['output']>;
  qst?: Maybe<Scalars['Float']['output']>;
  recordLocator?: Maybe<Scalars['String']['output']>;
  serviceFees?: Maybe<Scalars['Float']['output']>;
  serviceFeesTaxes?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type InvoiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InvoiceOrderByWithRelationInput = {
  billingAddressCode?: InputMaybe<SortOrder>;
  confirmation?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  dkNumber?: InputMaybe<SortOrder>;
  etlHash?: InputMaybe<SortOrderInput>;
  etlJob?: InputMaybe<EtlInvoiceJobOrderByWithRelationInput>;
  etlJobId?: InputMaybe<SortOrder>;
  gst?: InputMaybe<SortOrder>;
  hotelAddress?: InputMaybe<SortOrderInput>;
  hotelFees?: InputMaybe<SortOrder>;
  hst?: InputMaybe<SortOrder>;
  lines?: InputMaybe<InvoiceItemOrderByRelationAggregateInput>;
  number?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  posted?: InputMaybe<SortOrder>;
  printedAt?: InputMaybe<SortOrderInput>;
  pst?: InputMaybe<SortOrder>;
  qst?: InputMaybe<SortOrder>;
  recordLocator?: InputMaybe<SortOrder>;
  serviceFees?: InputMaybe<SortOrder>;
  serviceFeesTaxes?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  subtotal?: InputMaybe<SortOrder>;
  supplier?: InputMaybe<SortOrder>;
  total?: InputMaybe<SortOrder>;
  udids?: InputMaybe<CustomerUdidOnInvoicesOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InvoiceRelationFilter = {
  is?: InputMaybe<InvoiceWhereInput>;
  isNot?: InputMaybe<InvoiceWhereInput>;
};

export enum InvoiceScalarFieldEnum {
  BillingAddressCode = 'billingAddressCode',
  Confirmation = 'confirmation',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  CustomerId = 'customerId',
  DkNumber = 'dkNumber',
  EtlHash = 'etlHash',
  EtlJobId = 'etlJobId',
  Gst = 'gst',
  HotelAddress = 'hotelAddress',
  HotelFees = 'hotelFees',
  Hst = 'hst',
  Number = 'number',
  PaymentMethod = 'paymentMethod',
  Posted = 'posted',
  PrintedAt = 'printedAt',
  Pst = 'pst',
  Qst = 'qst',
  RecordLocator = 'recordLocator',
  ServiceFees = 'serviceFees',
  ServiceFeesTaxes = 'serviceFeesTaxes',
  State = 'state',
  Subtotal = 'subtotal',
  Supplier = 'supplier',
  Total = 'total',
  UpdatedAt = 'updatedAt'
}

export type InvoiceScalarWhereInput = {
  AND?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  OR?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  billingAddressCode?: InputMaybe<StringFilter>;
  confirmation?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  customerId?: InputMaybe<IntFilter>;
  dkNumber?: InputMaybe<StringFilter>;
  etlHash?: InputMaybe<StringNullableFilter>;
  etlJobId?: InputMaybe<IntFilter>;
  gst?: InputMaybe<FloatFilter>;
  hotelAddress?: InputMaybe<StringNullableFilter>;
  hotelFees?: InputMaybe<FloatFilter>;
  hst?: InputMaybe<FloatFilter>;
  number?: InputMaybe<IntFilter>;
  paymentMethod?: InputMaybe<StringFilter>;
  posted?: InputMaybe<DateTimeFilter>;
  printedAt?: InputMaybe<DateTimeNullableFilter>;
  pst?: InputMaybe<FloatFilter>;
  qst?: InputMaybe<FloatFilter>;
  recordLocator?: InputMaybe<StringFilter>;
  serviceFees?: InputMaybe<FloatFilter>;
  serviceFeesTaxes?: InputMaybe<FloatFilter>;
  state?: InputMaybe<StringFilter>;
  subtotal?: InputMaybe<FloatFilter>;
  supplier?: InputMaybe<StringFilter>;
  total?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InvoiceSumAggregate = {
  __typename?: 'InvoiceSumAggregate';
  customerId?: Maybe<Scalars['Int']['output']>;
  etlJobId?: Maybe<Scalars['Int']['output']>;
  gst?: Maybe<Scalars['Float']['output']>;
  hotelFees?: Maybe<Scalars['Float']['output']>;
  hst?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  pst?: Maybe<Scalars['Float']['output']>;
  qst?: Maybe<Scalars['Float']['output']>;
  serviceFees?: Maybe<Scalars['Float']['output']>;
  serviceFeesTaxes?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceUpdateManyMutationInput = {
  billingAddressCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  confirmation?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dkNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  etlHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hotelAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hotelFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<StringFieldUpdateOperationsInput>;
  posted?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  printedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  pst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  recordLocator?: InputMaybe<StringFieldUpdateOperationsInput>;
  serviceFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  serviceFeesTaxes?: InputMaybe<FloatFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  subtotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  supplier?: InputMaybe<StringFieldUpdateOperationsInput>;
  total?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvoiceUpdateManyWithWhereWithoutCustomerInput = {
  data: InvoiceUpdateManyMutationInput;
  where: InvoiceScalarWhereInput;
};

export type InvoiceUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<InvoiceCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  set?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  update?: InputMaybe<Array<InvoiceUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<InvoiceUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<InvoiceUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type InvoiceUpdateOneRequiredWithoutUdidsNestedInput = {
  connect?: InputMaybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InvoiceCreateOrConnectWithoutUdidsInput>;
  create?: InputMaybe<InvoiceCreateWithoutUdidsInput>;
  update?: InputMaybe<InvoiceUpdateToOneWithWhereWithoutUdidsInput>;
  upsert?: InputMaybe<InvoiceUpsertWithoutUdidsInput>;
};

export type InvoiceUpdateToOneWithWhereWithoutUdidsInput = {
  data: InvoiceUpdateWithoutUdidsInput;
  where?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceUpdateWithWhereUniqueWithoutCustomerInput = {
  data: InvoiceUpdateWithoutCustomerInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpdateWithoutCustomerInput = {
  billingAddressCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  confirmation?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dkNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  etlHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  etlJob?: InputMaybe<EtlInvoiceJobUpdateOneRequiredWithoutInvoicesNestedInput>;
  gst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hotelAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hotelFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  lines?: InputMaybe<InvoiceItemUpdateManyWithoutInvoiceNestedInput>;
  paymentMethod?: InputMaybe<StringFieldUpdateOperationsInput>;
  posted?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  printedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  pst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  recordLocator?: InputMaybe<StringFieldUpdateOperationsInput>;
  serviceFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  serviceFeesTaxes?: InputMaybe<FloatFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  subtotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  supplier?: InputMaybe<StringFieldUpdateOperationsInput>;
  total?: InputMaybe<FloatFieldUpdateOperationsInput>;
  udids?: InputMaybe<CustomerUdidOnInvoicesUpdateManyWithoutInvoiceNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvoiceUpdateWithoutUdidsInput = {
  billingAddressCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  confirmation?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutInvoicesNestedInput>;
  dkNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  etlHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  etlJob?: InputMaybe<EtlInvoiceJobUpdateOneRequiredWithoutInvoicesNestedInput>;
  gst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hotelAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hotelFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  hst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  lines?: InputMaybe<InvoiceItemUpdateManyWithoutInvoiceNestedInput>;
  paymentMethod?: InputMaybe<StringFieldUpdateOperationsInput>;
  posted?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  printedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  pst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  qst?: InputMaybe<FloatFieldUpdateOperationsInput>;
  recordLocator?: InputMaybe<StringFieldUpdateOperationsInput>;
  serviceFees?: InputMaybe<FloatFieldUpdateOperationsInput>;
  serviceFeesTaxes?: InputMaybe<FloatFieldUpdateOperationsInput>;
  state?: InputMaybe<StringFieldUpdateOperationsInput>;
  subtotal?: InputMaybe<FloatFieldUpdateOperationsInput>;
  supplier?: InputMaybe<StringFieldUpdateOperationsInput>;
  total?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InvoiceUpsertWithWhereUniqueWithoutCustomerInput = {
  create: InvoiceCreateWithoutCustomerInput;
  update: InvoiceUpdateWithoutCustomerInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpsertWithoutUdidsInput = {
  create: InvoiceCreateWithoutUdidsInput;
  update: InvoiceUpdateWithoutUdidsInput;
  where?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceWhereInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  billingAddressCode?: InputMaybe<StringFilter>;
  confirmation?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<IntFilter>;
  dkNumber?: InputMaybe<StringFilter>;
  etlHash?: InputMaybe<StringNullableFilter>;
  etlJob?: InputMaybe<EtlInvoiceJobRelationFilter>;
  etlJobId?: InputMaybe<IntFilter>;
  gst?: InputMaybe<FloatFilter>;
  hotelAddress?: InputMaybe<StringNullableFilter>;
  hotelFees?: InputMaybe<FloatFilter>;
  hst?: InputMaybe<FloatFilter>;
  lines?: InputMaybe<InvoiceItemListRelationFilter>;
  number?: InputMaybe<IntFilter>;
  paymentMethod?: InputMaybe<StringFilter>;
  posted?: InputMaybe<DateTimeFilter>;
  printedAt?: InputMaybe<DateTimeNullableFilter>;
  pst?: InputMaybe<FloatFilter>;
  qst?: InputMaybe<FloatFilter>;
  recordLocator?: InputMaybe<StringFilter>;
  serviceFees?: InputMaybe<FloatFilter>;
  serviceFeesTaxes?: InputMaybe<FloatFilter>;
  state?: InputMaybe<StringFilter>;
  subtotal?: InputMaybe<FloatFilter>;
  supplier?: InputMaybe<StringFilter>;
  total?: InputMaybe<FloatFilter>;
  udids?: InputMaybe<CustomerUdidOnInvoicesListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InvoiceWhereUniqueInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  billingAddressCode?: InputMaybe<StringFilter>;
  confirmation?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<IntFilter>;
  dkNumber?: InputMaybe<StringFilter>;
  etlHash?: InputMaybe<StringNullableFilter>;
  etlJob?: InputMaybe<EtlInvoiceJobRelationFilter>;
  etlJobId?: InputMaybe<IntFilter>;
  gst?: InputMaybe<FloatFilter>;
  hotelAddress?: InputMaybe<StringNullableFilter>;
  hotelFees?: InputMaybe<FloatFilter>;
  hst?: InputMaybe<FloatFilter>;
  lines?: InputMaybe<InvoiceItemListRelationFilter>;
  number?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<StringFilter>;
  posted?: InputMaybe<DateTimeFilter>;
  printedAt?: InputMaybe<DateTimeNullableFilter>;
  pst?: InputMaybe<FloatFilter>;
  qst?: InputMaybe<FloatFilter>;
  recordLocator?: InputMaybe<StringFilter>;
  serviceFees?: InputMaybe<FloatFilter>;
  serviceFeesTaxes?: InputMaybe<FloatFilter>;
  state?: InputMaybe<StringFilter>;
  subtotal?: InputMaybe<FloatFilter>;
  supplier?: InputMaybe<StringFilter>;
  total?: InputMaybe<FloatFilter>;
  udids?: InputMaybe<CustomerUdidOnInvoicesListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOneCustomer: Customer;
  createOneIUserCust: IUser;
  deleteOneCustomer?: Maybe<Customer>;
  deleteOneIUser?: Maybe<IUser>;
  runETLInvoiceJob: EtlInvoiceJob;
  runPDFInvoicesRep: EtlInvoiceJob;
  runPipelineInvoices: EtlInvoiceJob;
  updateOneCustomer?: Maybe<Customer>;
  updateOneIUserCust?: Maybe<IUser>;
};


export type MutationCreateOneCustomerArgs = {
  data: CustomerCreateInput;
};


export type MutationCreateOneIUserCustArgs = {
  data: IUserCreateInput;
};


export type MutationDeleteOneCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type MutationDeleteOneIUserArgs = {
  where: IUserWhereUniqueInput;
};


export type MutationRunEtlInvoiceJobArgs = {
  data: EtlInvoiceJobRunInput;
};


export type MutationRunPdfInvoicesRepArgs = {
  data: PdfInvoicesRepInput;
};


export type MutationRunPipelineInvoicesArgs = {
  data: PipelineInvoicesRunInput;
};


export type MutationUpdateOneCustomerArgs = {
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpdateOneIUserCustArgs = {
  data: IUserUpdateInput;
  where: IUserWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type PdfInvoicesRepInput = {
  etlJobId: Scalars['Float']['input'];
};

export type PipelineInvoicesRunInput = {
  dataId: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  aggregateCustomer: AggregateCustomer;
  aggregateETLInvoiceJob: AggregateEtlInvoiceJob;
  aggregateIUser: AggregateIUser;
  aggregateInvoice: AggregateInvoice;
  aggregateRole: AggregateRole;
  customer?: Maybe<Customer>;
  customers: Array<Customer>;
  eTLInvoiceJob?: Maybe<EtlInvoiceJob>;
  eTLInvoiceJobs: Array<EtlInvoiceJob>;
  etlFiles: Array<EtlFile>;
  findFirstIUser?: Maybe<IUser>;
  findFirstIUserOrThrow?: Maybe<IUser>;
  iUser?: Maybe<IUser>;
  iUsers: Array<IUser>;
  invoice?: Maybe<Invoice>;
  invoices: Array<Invoice>;
  role?: Maybe<Role>;
  roles: Array<Role>;
};


export type QueryAggregateCustomerArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryAggregateEtlInvoiceJobArgs = {
  cursor?: InputMaybe<EtlInvoiceJobWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EtlInvoiceJobOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EtlInvoiceJobWhereInput>;
};


export type QueryAggregateIUserArgs = {
  cursor?: InputMaybe<IUserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<IUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IUserWhereInput>;
};


export type QueryAggregateInvoiceArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryAggregateRoleArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoleWhereInput>;
};


export type QueryCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type QueryCustomersArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryETlInvoiceJobArgs = {
  where: EtlInvoiceJobWhereUniqueInput;
};


export type QueryETlInvoiceJobsArgs = {
  cursor?: InputMaybe<EtlInvoiceJobWhereUniqueInput>;
  distinct?: InputMaybe<Array<EtlInvoiceJobScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EtlInvoiceJobOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EtlInvoiceJobWhereInput>;
};


export type QueryEtlFilesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EtlFileWhereInput>;
};


export type QueryFindFirstIUserArgs = {
  cursor?: InputMaybe<IUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<IUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IUserWhereInput>;
};


export type QueryFindFirstIUserOrThrowArgs = {
  cursor?: InputMaybe<IUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<IUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IUserWhereInput>;
};


export type QueryIUserArgs = {
  where: IUserWhereUniqueInput;
};


export type QueryIUsersArgs = {
  cursor?: InputMaybe<IUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<IUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IUserWhereInput>;
};


export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryInvoicesArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryRoleArgs = {
  where: RoleWhereUniqueInput;
};


export type QueryRolesArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoleWhereInput>;
};

export type Role = {
  __typename?: 'Role';
  _count?: Maybe<RoleCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  users: Array<IUser>;
};


export type RoleUsersArgs = {
  cursor?: InputMaybe<IUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<IUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IUserWhereInput>;
};

export type RoleAvgAggregate = {
  __typename?: 'RoleAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type RoleCount = {
  __typename?: 'RoleCount';
  users: Scalars['Int']['output'];
};


export type RoleCountUsersArgs = {
  where?: InputMaybe<IUserWhereInput>;
};

export type RoleCountAggregate = {
  __typename?: 'RoleCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type RoleCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutUsersInput>>;
};

export type RoleCreateOrConnectWithoutUsersInput = {
  create: RoleCreateWithoutUsersInput;
  where: RoleWhereUniqueInput;
};

export type RoleCreateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type RoleListRelationFilter = {
  every?: InputMaybe<RoleWhereInput>;
  none?: InputMaybe<RoleWhereInput>;
  some?: InputMaybe<RoleWhereInput>;
};

export type RoleMaxAggregate = {
  __typename?: 'RoleMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type RoleMinAggregate = {
  __typename?: 'RoleMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type RoleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RoleOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<IUserOrderByRelationAggregateInput>;
};

export enum RoleScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type RoleScalarWhereInput = {
  AND?: InputMaybe<Array<RoleScalarWhereInput>>;
  NOT?: InputMaybe<Array<RoleScalarWhereInput>>;
  OR?: InputMaybe<Array<RoleScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RoleSumAggregate = {
  __typename?: 'RoleSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type RoleUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RoleUpdateManyWithWhereWithoutUsersInput = {
  data: RoleUpdateManyMutationInput;
  where: RoleScalarWhereInput;
};

export type RoleUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<RoleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  set?: InputMaybe<Array<RoleWhereUniqueInput>>;
  update?: InputMaybe<Array<RoleUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<RoleUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<RoleUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type RoleUpdateWithWhereUniqueWithoutUsersInput = {
  data: RoleUpdateWithoutUsersInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpdateWithoutUsersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RoleUpsertWithWhereUniqueWithoutUsersInput = {
  create: RoleCreateWithoutUsersInput;
  update: RoleUpdateWithoutUsersInput;
  where: RoleWhereUniqueInput;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<IUserListRelationFilter>;
};

export type RoleWhereUniqueInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<IUserListRelationFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerCreateOneMutationVariables = Exact<{
  data: CustomerCreateInput;
}>;


export type CustomerCreateOneMutation = { __typename?: 'Mutation', createOneCustomer: { __typename?: 'Customer', city: string, country: string, dkNumber: string, name: string, paymentTerm: string, postal: string, state: string, street: string, udids: Array<{ __typename?: 'CustomerUDID', udid: string, displayName: string }>, addresses: Array<{ __typename?: 'BillingAddress', city: string, code: string, country: string, name: string, postal: string, state: string, street: string }> } };

export type CustomerUpdateOneMutationVariables = Exact<{
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
}>;


export type CustomerUpdateOneMutation = { __typename?: 'Mutation', updateOneCustomer?: { __typename?: 'Customer', id: number, dkNumber: string, name: string, paymentTerm: string, postal: string, state: string, street: string, updatedAt: any, createdAt: any, country: string, city: string, udids: Array<{ __typename?: 'CustomerUDID', udid: string, displayName: string, id: number, customerId: number, createdAt: any, updatedAt: any }>, addresses: Array<{ __typename?: 'BillingAddress', id: number, name: string, postal: string, state: string, street: string, city: string, code: string, country: string, customerId: number }> } | null };

export type CustomerFullQueryVariables = Exact<{
  where: CustomerWhereUniqueInput;
}>;


export type CustomerFullQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', id: number, dkNumber: string, name: string, paymentTerm: string, postal: string, state: string, street: string, updatedAt: any, createdAt: any, country: string, city: string, udids: Array<{ __typename?: 'CustomerUDID', udid: string, displayName: string, id: number, customerId: number, createdAt: any, updatedAt: any }>, addresses: Array<{ __typename?: 'BillingAddress', id: number, name: string, postal: string, state: string, street: string, city: string, code: string, country: string, customerId: number }> } | null };

export type CustomerUdiDsQueryVariables = Exact<{
  where: CustomerWhereUniqueInput;
}>;


export type CustomerUdiDsQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', udids: Array<{ __typename?: 'CustomerUDID', id: number, udid: string, displayName: string }> } | null };

export type CustomersWhereQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput> | CustomerOrderByWithRelationInput>;
}>;


export type CustomersWhereQuery = { __typename?: 'Query', customers: Array<{ __typename?: 'Customer', city: string, dkNumber: string, name: string, paymentTerm: string, postal: string, state: string, street: string, updatedAt: any, id: number, createdAt: any, country: string, _count?: { __typename?: 'CustomerCount', addresses: number, udids: number } | null }>, aggregateCustomer: { __typename?: 'AggregateCustomer', _count?: { __typename?: 'CustomerCountAggregate', id: number } | null } };

export type CustomersUdiDsQueryVariables = Exact<{
  where?: InputMaybe<CustomerUdidWhereInput>;
}>;


export type CustomersUdiDsQuery = { __typename?: 'Query', customers: Array<{ __typename?: 'Customer', udids: Array<{ __typename?: 'CustomerUDID', id: number }> }> };

export type CustomersUdiDsAllQueryVariables = Exact<{
  where?: InputMaybe<CustomerWhereInput>;
}>;


export type CustomersUdiDsAllQuery = { __typename?: 'Query', customers: Array<{ __typename?: 'Customer', dkNumber: string, udids: Array<{ __typename?: 'CustomerUDID', id: number, udid: string, displayName: string }> }> };

export type EtlFilesQueryVariables = Exact<{
  where?: InputMaybe<EtlFileWhereInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type EtlFilesQuery = { __typename?: 'Query', etlFiles: Array<{ __typename?: 'ETLFile', ctime: any, hash: string, mtime: any, name: string, size: number, total: number }> };

export type EtlInvoiceJobFullQueryVariables = Exact<{
  where: EtlInvoiceJobWhereUniqueInput;
}>;


export type EtlInvoiceJobFullQuery = { __typename?: 'Query', eTLInvoiceJob?: { __typename?: 'ETLInvoiceJob', id: number, createdAt: any, updatedAt: any, importedAt?: any | null, dataId: string, etlId: string, dataHash: string, importedTimes?: number | null, errors?: string | null, status: string, from?: any | null, to?: any | null, emails?: string | null, invoices: Array<{ __typename?: 'Invoice', dkNumber: string, number: number, total: number, createdAt: any, recordLocator: string }> } | null };

export type EtlInvoiceJobsWhereQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EtlInvoiceJobWhereInput>;
}>;


export type EtlInvoiceJobsWhereQuery = { __typename?: 'Query', aggregateETLInvoiceJob: { __typename?: 'AggregateETLInvoiceJob', _count?: { __typename?: 'ETLInvoiceJobCountAggregate', id: number } | null }, eTLInvoiceJobs: Array<{ __typename?: 'ETLInvoiceJob', id: number, from?: any | null, to?: any | null, emails?: string | null, dataId: string, dataHash: string, createdAt: any, errors?: string | null, status: string, updatedAt: any, importedTimes?: number | null, importedAt?: any | null, etlId: string, _count?: { __typename?: 'ETLInvoiceJobCount', invoices: number } | null }> };

export type InvoiceFullQueryVariables = Exact<{
  where: InvoiceWhereUniqueInput;
}>;


export type InvoiceFullQuery = { __typename?: 'Query', invoice?: { __typename?: 'Invoice', number: number, etlJobId: number, etlHash?: string | null, recordLocator: string, confirmation: string, state: string, dkNumber: string, currency: string, subtotal: number, gst: number, pst: number, hst: number, qst: number, hotelFees: number, serviceFees: number, serviceFeesTaxes: number, supplier: string, total: number, posted: any, hotelAddress?: string | null, paymentMethod: string, customerId: number, printedAt?: any | null, createdAt: any, updatedAt: any, billingAddressCode: string, lines: Array<{ __typename?: 'InvoiceItem', id: number, bookedNights: number, traveler: string, checkIn: any, checkOut: any, dailyRate: number, roomType: string, total: number }>, udids: Array<{ __typename?: 'CustomerUDIDOnInvoices', value: string, customerUDID: { __typename?: 'CustomerUDID', udid: string, displayName: string } }> } | null };

export type InvoicesWhereQueryVariables = Exact<{
  where?: InputMaybe<InvoiceWhereInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type InvoicesWhereQuery = { __typename?: 'Query', aggregateInvoice: { __typename?: 'AggregateInvoice', _count?: { __typename?: 'InvoiceCountAggregate', number: number } | null }, invoices: Array<{ __typename?: 'Invoice', etlJobId: number, number: number, dkNumber: string, recordLocator: string, createdAt: any, state: string, subtotal: number, hotelFees: number, serviceFees: number, gst: number, pst: number, hst: number, qst: number, total: number, _count?: { __typename?: 'InvoiceCount', lines: number } | null }> };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles: Array<{ __typename?: 'Role', name: string, id: number, updatedAt: any, createdAt: any }> };

export type CreateOneIUserCustMutationVariables = Exact<{
  data: IUserCreateInput;
}>;


export type CreateOneIUserCustMutation = { __typename?: 'Mutation', createOneIUserCust: { __typename?: 'IUser', email: string, lastName: string, name: string, id: number, enabled: boolean, roles: Array<{ __typename?: 'Role', id: number, name: string }>, customers: Array<{ __typename?: 'Customer', id: number, name: string, dkNumber: string }> } };

export type DeleteOneIUserMutationVariables = Exact<{
  where: IUserWhereUniqueInput;
}>;


export type DeleteOneIUserMutation = { __typename?: 'Mutation', deleteOneIUser?: { __typename?: 'IUser', id: number } | null };

export type UpdateOneIUserCustMutationVariables = Exact<{
  data: IUserUpdateInput;
  where: IUserWhereUniqueInput;
}>;


export type UpdateOneIUserCustMutation = { __typename?: 'Mutation', updateOneIUserCust?: { __typename?: 'IUser', email: string, lastName: string, name: string, id: number, enabled: boolean, roles: Array<{ __typename?: 'Role', id: number, name: string }>, customers: Array<{ __typename?: 'Customer', id: number, name: string, dkNumber: string }> } | null };

export type IUserFullQueryVariables = Exact<{
  where: IUserWhereUniqueInput;
}>;


export type IUserFullQuery = { __typename?: 'Query', iUser?: { __typename?: 'IUser', createdAt: any, updatedAt: any, salt: string, enabled: boolean, name: string, lastName: string, id: number, email: string, customers: Array<{ __typename?: 'Customer', id: number, dkNumber: string, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> } | null };

export type IUsersWhereQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IUserWhereInput>;
}>;


export type IUsersWhereQuery = { __typename?: 'Query', iUsers: Array<{ __typename?: 'IUser', id: number, email: string, createdAt: any, updatedAt: any, lastName: string, name: string, enabled: boolean, roles: Array<{ __typename?: 'Role', id: number, name: string }>, customers: Array<{ __typename?: 'Customer', id: number, name: string, dkNumber: string }> }>, aggregateIUser: { __typename?: 'AggregateIUser', _count?: { __typename?: 'IUserCountAggregate', _all: number } | null } };


export const CustomerCreateOneDocument = gql`
    mutation CustomerCreateOne($data: CustomerCreateInput!) {
  createOneCustomer(data: $data) {
    city
    country
    dkNumber
    name
    paymentTerm
    postal
    state
    street
    udids {
      udid
      displayName
    }
    addresses {
      city
      code
      country
      name
      postal
      state
      street
    }
  }
}
    `;
export type CustomerCreateOneMutationFn = Apollo.MutationFunction<CustomerCreateOneMutation, CustomerCreateOneMutationVariables>;

/**
 * __useCustomerCreateOneMutation__
 *
 * To run a mutation, you first call `useCustomerCreateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerCreateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerCreateOneMutation, { data, loading, error }] = useCustomerCreateOneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCustomerCreateOneMutation(baseOptions?: Apollo.MutationHookOptions<CustomerCreateOneMutation, CustomerCreateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerCreateOneMutation, CustomerCreateOneMutationVariables>(CustomerCreateOneDocument, options);
      }
export type CustomerCreateOneMutationHookResult = ReturnType<typeof useCustomerCreateOneMutation>;
export type CustomerCreateOneMutationResult = Apollo.MutationResult<CustomerCreateOneMutation>;
export type CustomerCreateOneMutationOptions = Apollo.BaseMutationOptions<CustomerCreateOneMutation, CustomerCreateOneMutationVariables>;
export const CustomerUpdateOneDocument = gql`
    mutation CustomerUpdateOne($data: CustomerUpdateInput!, $where: CustomerWhereUniqueInput!) {
  updateOneCustomer(data: $data, where: $where) {
    id
    dkNumber
    name
    paymentTerm
    postal
    state
    street
    updatedAt
    createdAt
    country
    city
    udids {
      udid
      displayName
      id
      customerId
      createdAt
      updatedAt
    }
    addresses {
      id
      name
      postal
      state
      street
      city
      code
      country
      customerId
    }
  }
}
    `;
export type CustomerUpdateOneMutationFn = Apollo.MutationFunction<CustomerUpdateOneMutation, CustomerUpdateOneMutationVariables>;

/**
 * __useCustomerUpdateOneMutation__
 *
 * To run a mutation, you first call `useCustomerUpdateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerUpdateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerUpdateOneMutation, { data, loading, error }] = useCustomerUpdateOneMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomerUpdateOneMutation(baseOptions?: Apollo.MutationHookOptions<CustomerUpdateOneMutation, CustomerUpdateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerUpdateOneMutation, CustomerUpdateOneMutationVariables>(CustomerUpdateOneDocument, options);
      }
export type CustomerUpdateOneMutationHookResult = ReturnType<typeof useCustomerUpdateOneMutation>;
export type CustomerUpdateOneMutationResult = Apollo.MutationResult<CustomerUpdateOneMutation>;
export type CustomerUpdateOneMutationOptions = Apollo.BaseMutationOptions<CustomerUpdateOneMutation, CustomerUpdateOneMutationVariables>;
export const CustomerFullDocument = gql`
    query CustomerFull($where: CustomerWhereUniqueInput!) {
  customer(where: $where) {
    id
    dkNumber
    name
    paymentTerm
    postal
    state
    street
    updatedAt
    createdAt
    country
    city
    udids {
      udid
      displayName
      id
      customerId
      createdAt
      updatedAt
    }
    addresses {
      id
      name
      postal
      state
      street
      city
      code
      country
      customerId
    }
  }
}
    `;

/**
 * __useCustomerFullQuery__
 *
 * To run a query within a React component, call `useCustomerFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerFullQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomerFullQuery(baseOptions: Apollo.QueryHookOptions<CustomerFullQuery, CustomerFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerFullQuery, CustomerFullQueryVariables>(CustomerFullDocument, options);
      }
export function useCustomerFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerFullQuery, CustomerFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerFullQuery, CustomerFullQueryVariables>(CustomerFullDocument, options);
        }
export type CustomerFullQueryHookResult = ReturnType<typeof useCustomerFullQuery>;
export type CustomerFullLazyQueryHookResult = ReturnType<typeof useCustomerFullLazyQuery>;
export type CustomerFullQueryResult = Apollo.QueryResult<CustomerFullQuery, CustomerFullQueryVariables>;
export const CustomerUdiDsDocument = gql`
    query CustomerUDIDs($where: CustomerWhereUniqueInput!) {
  customer(where: $where) {
    udids {
      id
      udid
      displayName
    }
  }
}
    `;

/**
 * __useCustomerUdiDsQuery__
 *
 * To run a query within a React component, call `useCustomerUdiDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerUdiDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerUdiDsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomerUdiDsQuery(baseOptions: Apollo.QueryHookOptions<CustomerUdiDsQuery, CustomerUdiDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerUdiDsQuery, CustomerUdiDsQueryVariables>(CustomerUdiDsDocument, options);
      }
export function useCustomerUdiDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerUdiDsQuery, CustomerUdiDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerUdiDsQuery, CustomerUdiDsQueryVariables>(CustomerUdiDsDocument, options);
        }
export type CustomerUdiDsQueryHookResult = ReturnType<typeof useCustomerUdiDsQuery>;
export type CustomerUdiDsLazyQueryHookResult = ReturnType<typeof useCustomerUdiDsLazyQuery>;
export type CustomerUdiDsQueryResult = Apollo.QueryResult<CustomerUdiDsQuery, CustomerUdiDsQueryVariables>;
export const CustomersWhereDocument = gql`
    query CustomersWhere($take: Int, $skip: Int, $where: CustomerWhereInput, $orderBy: [CustomerOrderByWithRelationInput!]) {
  customers(take: $take, skip: $skip, where: $where) {
    _count {
      addresses
      udids
    }
    city
    dkNumber
    name
    paymentTerm
    postal
    state
    street
    updatedAt
    id
    createdAt
    country
  }
  aggregateCustomer(where: $where, orderBy: $orderBy) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useCustomersWhereQuery__
 *
 * To run a query within a React component, call `useCustomersWhereQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersWhereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersWhereQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCustomersWhereQuery(baseOptions?: Apollo.QueryHookOptions<CustomersWhereQuery, CustomersWhereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersWhereQuery, CustomersWhereQueryVariables>(CustomersWhereDocument, options);
      }
export function useCustomersWhereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersWhereQuery, CustomersWhereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersWhereQuery, CustomersWhereQueryVariables>(CustomersWhereDocument, options);
        }
export type CustomersWhereQueryHookResult = ReturnType<typeof useCustomersWhereQuery>;
export type CustomersWhereLazyQueryHookResult = ReturnType<typeof useCustomersWhereLazyQuery>;
export type CustomersWhereQueryResult = Apollo.QueryResult<CustomersWhereQuery, CustomersWhereQueryVariables>;
export const CustomersUdiDsDocument = gql`
    query CustomersUDIDs($where: CustomerUDIDWhereInput) {
  customers {
    udids(where: $where) {
      id
    }
  }
}
    `;

/**
 * __useCustomersUdiDsQuery__
 *
 * To run a query within a React component, call `useCustomersUdiDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersUdiDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersUdiDsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomersUdiDsQuery(baseOptions?: Apollo.QueryHookOptions<CustomersUdiDsQuery, CustomersUdiDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersUdiDsQuery, CustomersUdiDsQueryVariables>(CustomersUdiDsDocument, options);
      }
export function useCustomersUdiDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersUdiDsQuery, CustomersUdiDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersUdiDsQuery, CustomersUdiDsQueryVariables>(CustomersUdiDsDocument, options);
        }
export type CustomersUdiDsQueryHookResult = ReturnType<typeof useCustomersUdiDsQuery>;
export type CustomersUdiDsLazyQueryHookResult = ReturnType<typeof useCustomersUdiDsLazyQuery>;
export type CustomersUdiDsQueryResult = Apollo.QueryResult<CustomersUdiDsQuery, CustomersUdiDsQueryVariables>;
export const CustomersUdiDsAllDocument = gql`
    query CustomersUDIDsAll($where: CustomerWhereInput) {
  customers(where: $where) {
    dkNumber
    udids {
      id
      udid
      displayName
    }
  }
}
    `;

/**
 * __useCustomersUdiDsAllQuery__
 *
 * To run a query within a React component, call `useCustomersUdiDsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersUdiDsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersUdiDsAllQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomersUdiDsAllQuery(baseOptions?: Apollo.QueryHookOptions<CustomersUdiDsAllQuery, CustomersUdiDsAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersUdiDsAllQuery, CustomersUdiDsAllQueryVariables>(CustomersUdiDsAllDocument, options);
      }
export function useCustomersUdiDsAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersUdiDsAllQuery, CustomersUdiDsAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersUdiDsAllQuery, CustomersUdiDsAllQueryVariables>(CustomersUdiDsAllDocument, options);
        }
export type CustomersUdiDsAllQueryHookResult = ReturnType<typeof useCustomersUdiDsAllQuery>;
export type CustomersUdiDsAllLazyQueryHookResult = ReturnType<typeof useCustomersUdiDsAllLazyQuery>;
export type CustomersUdiDsAllQueryResult = Apollo.QueryResult<CustomersUdiDsAllQuery, CustomersUdiDsAllQueryVariables>;
export const EtlFilesDocument = gql`
    query EtlFiles($where: ETLFileWhereInput, $take: Int, $skip: Int) {
  etlFiles(where: $where, take: $take, skip: $skip) {
    ctime
    hash
    mtime
    name
    size
    total
  }
}
    `;

/**
 * __useEtlFilesQuery__
 *
 * To run a query within a React component, call `useEtlFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEtlFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEtlFilesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useEtlFilesQuery(baseOptions?: Apollo.QueryHookOptions<EtlFilesQuery, EtlFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EtlFilesQuery, EtlFilesQueryVariables>(EtlFilesDocument, options);
      }
export function useEtlFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EtlFilesQuery, EtlFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EtlFilesQuery, EtlFilesQueryVariables>(EtlFilesDocument, options);
        }
export type EtlFilesQueryHookResult = ReturnType<typeof useEtlFilesQuery>;
export type EtlFilesLazyQueryHookResult = ReturnType<typeof useEtlFilesLazyQuery>;
export type EtlFilesQueryResult = Apollo.QueryResult<EtlFilesQuery, EtlFilesQueryVariables>;
export const EtlInvoiceJobFullDocument = gql`
    query ETLInvoiceJobFull($where: ETLInvoiceJobWhereUniqueInput!) {
  eTLInvoiceJob(where: $where) {
    id
    createdAt
    updatedAt
    importedAt
    dataId
    etlId
    dataHash
    importedTimes
    errors
    status
    from
    to
    emails
    invoices {
      dkNumber
      number
      total
      createdAt
      recordLocator
    }
  }
}
    `;

/**
 * __useEtlInvoiceJobFullQuery__
 *
 * To run a query within a React component, call `useEtlInvoiceJobFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useEtlInvoiceJobFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEtlInvoiceJobFullQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEtlInvoiceJobFullQuery(baseOptions: Apollo.QueryHookOptions<EtlInvoiceJobFullQuery, EtlInvoiceJobFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EtlInvoiceJobFullQuery, EtlInvoiceJobFullQueryVariables>(EtlInvoiceJobFullDocument, options);
      }
export function useEtlInvoiceJobFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EtlInvoiceJobFullQuery, EtlInvoiceJobFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EtlInvoiceJobFullQuery, EtlInvoiceJobFullQueryVariables>(EtlInvoiceJobFullDocument, options);
        }
export type EtlInvoiceJobFullQueryHookResult = ReturnType<typeof useEtlInvoiceJobFullQuery>;
export type EtlInvoiceJobFullLazyQueryHookResult = ReturnType<typeof useEtlInvoiceJobFullLazyQuery>;
export type EtlInvoiceJobFullQueryResult = Apollo.QueryResult<EtlInvoiceJobFullQuery, EtlInvoiceJobFullQueryVariables>;
export const EtlInvoiceJobsWhereDocument = gql`
    query ETLInvoiceJobsWhere($skip: Int, $take: Int, $where: ETLInvoiceJobWhereInput) {
  aggregateETLInvoiceJob(where: $where) {
    _count {
      id
    }
  }
  eTLInvoiceJobs(skip: $skip, take: $take, where: $where) {
    id
    from
    to
    emails
    dataId
    dataHash
    createdAt
    errors
    status
    updatedAt
    importedTimes
    importedAt
    etlId
    _count {
      invoices
    }
  }
}
    `;

/**
 * __useEtlInvoiceJobsWhereQuery__
 *
 * To run a query within a React component, call `useEtlInvoiceJobsWhereQuery` and pass it any options that fit your needs.
 * When your component renders, `useEtlInvoiceJobsWhereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEtlInvoiceJobsWhereQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEtlInvoiceJobsWhereQuery(baseOptions?: Apollo.QueryHookOptions<EtlInvoiceJobsWhereQuery, EtlInvoiceJobsWhereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EtlInvoiceJobsWhereQuery, EtlInvoiceJobsWhereQueryVariables>(EtlInvoiceJobsWhereDocument, options);
      }
export function useEtlInvoiceJobsWhereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EtlInvoiceJobsWhereQuery, EtlInvoiceJobsWhereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EtlInvoiceJobsWhereQuery, EtlInvoiceJobsWhereQueryVariables>(EtlInvoiceJobsWhereDocument, options);
        }
export type EtlInvoiceJobsWhereQueryHookResult = ReturnType<typeof useEtlInvoiceJobsWhereQuery>;
export type EtlInvoiceJobsWhereLazyQueryHookResult = ReturnType<typeof useEtlInvoiceJobsWhereLazyQuery>;
export type EtlInvoiceJobsWhereQueryResult = Apollo.QueryResult<EtlInvoiceJobsWhereQuery, EtlInvoiceJobsWhereQueryVariables>;
export const InvoiceFullDocument = gql`
    query InvoiceFull($where: InvoiceWhereUniqueInput!) {
  invoice(where: $where) {
    number
    etlJobId
    etlHash
    recordLocator
    confirmation
    state
    dkNumber
    currency
    subtotal
    gst
    pst
    hst
    qst
    hotelFees
    serviceFees
    serviceFeesTaxes
    supplier
    total
    posted
    hotelAddress
    supplier
    paymentMethod
    customerId
    printedAt
    createdAt
    updatedAt
    billingAddressCode
    lines {
      id
      bookedNights
      traveler
      checkIn
      checkOut
      dailyRate
      roomType
      total
    }
    udids {
      value
      customerUDID {
        udid
        displayName
      }
    }
  }
}
    `;

/**
 * __useInvoiceFullQuery__
 *
 * To run a query within a React component, call `useInvoiceFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceFullQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInvoiceFullQuery(baseOptions: Apollo.QueryHookOptions<InvoiceFullQuery, InvoiceFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceFullQuery, InvoiceFullQueryVariables>(InvoiceFullDocument, options);
      }
export function useInvoiceFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceFullQuery, InvoiceFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceFullQuery, InvoiceFullQueryVariables>(InvoiceFullDocument, options);
        }
export type InvoiceFullQueryHookResult = ReturnType<typeof useInvoiceFullQuery>;
export type InvoiceFullLazyQueryHookResult = ReturnType<typeof useInvoiceFullLazyQuery>;
export type InvoiceFullQueryResult = Apollo.QueryResult<InvoiceFullQuery, InvoiceFullQueryVariables>;
export const InvoicesWhereDocument = gql`
    query InvoicesWhere($where: InvoiceWhereInput, $skip: Int, $take: Int) {
  aggregateInvoice(where: $where) {
    _count {
      number
    }
  }
  invoices(where: $where, skip: $skip, take: $take) {
    etlJobId
    number
    dkNumber
    recordLocator
    createdAt
    state
    subtotal
    hotelFees
    serviceFees
    gst
    pst
    hst
    qst
    total
    _count {
      lines
    }
  }
}
    `;

/**
 * __useInvoicesWhereQuery__
 *
 * To run a query within a React component, call `useInvoicesWhereQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesWhereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesWhereQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useInvoicesWhereQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesWhereQuery, InvoicesWhereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesWhereQuery, InvoicesWhereQueryVariables>(InvoicesWhereDocument, options);
      }
export function useInvoicesWhereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesWhereQuery, InvoicesWhereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesWhereQuery, InvoicesWhereQueryVariables>(InvoicesWhereDocument, options);
        }
export type InvoicesWhereQueryHookResult = ReturnType<typeof useInvoicesWhereQuery>;
export type InvoicesWhereLazyQueryHookResult = ReturnType<typeof useInvoicesWhereLazyQuery>;
export type InvoicesWhereQueryResult = Apollo.QueryResult<InvoicesWhereQuery, InvoicesWhereQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    name
    id
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const CreateOneIUserCustDocument = gql`
    mutation CreateOneIUserCust($data: IUserCreateInput!) {
  createOneIUserCust(data: $data) {
    email
    lastName
    name
    id
    roles {
      id
      name
    }
    customers {
      id
      name
      dkNumber
    }
    enabled
  }
}
    `;
export type CreateOneIUserCustMutationFn = Apollo.MutationFunction<CreateOneIUserCustMutation, CreateOneIUserCustMutationVariables>;

/**
 * __useCreateOneIUserCustMutation__
 *
 * To run a mutation, you first call `useCreateOneIUserCustMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneIUserCustMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneIUserCustMutation, { data, loading, error }] = useCreateOneIUserCustMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneIUserCustMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneIUserCustMutation, CreateOneIUserCustMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneIUserCustMutation, CreateOneIUserCustMutationVariables>(CreateOneIUserCustDocument, options);
      }
export type CreateOneIUserCustMutationHookResult = ReturnType<typeof useCreateOneIUserCustMutation>;
export type CreateOneIUserCustMutationResult = Apollo.MutationResult<CreateOneIUserCustMutation>;
export type CreateOneIUserCustMutationOptions = Apollo.BaseMutationOptions<CreateOneIUserCustMutation, CreateOneIUserCustMutationVariables>;
export const DeleteOneIUserDocument = gql`
    mutation DeleteOneIUser($where: IUserWhereUniqueInput!) {
  deleteOneIUser(where: $where) {
    id
  }
}
    `;
export type DeleteOneIUserMutationFn = Apollo.MutationFunction<DeleteOneIUserMutation, DeleteOneIUserMutationVariables>;

/**
 * __useDeleteOneIUserMutation__
 *
 * To run a mutation, you first call `useDeleteOneIUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneIUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneIUserMutation, { data, loading, error }] = useDeleteOneIUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneIUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneIUserMutation, DeleteOneIUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneIUserMutation, DeleteOneIUserMutationVariables>(DeleteOneIUserDocument, options);
      }
export type DeleteOneIUserMutationHookResult = ReturnType<typeof useDeleteOneIUserMutation>;
export type DeleteOneIUserMutationResult = Apollo.MutationResult<DeleteOneIUserMutation>;
export type DeleteOneIUserMutationOptions = Apollo.BaseMutationOptions<DeleteOneIUserMutation, DeleteOneIUserMutationVariables>;
export const UpdateOneIUserCustDocument = gql`
    mutation UpdateOneIUserCust($data: IUserUpdateInput!, $where: IUserWhereUniqueInput!) {
  updateOneIUserCust(data: $data, where: $where) {
    email
    lastName
    name
    id
    roles {
      id
      name
    }
    customers {
      id
      name
      dkNumber
    }
    enabled
  }
}
    `;
export type UpdateOneIUserCustMutationFn = Apollo.MutationFunction<UpdateOneIUserCustMutation, UpdateOneIUserCustMutationVariables>;

/**
 * __useUpdateOneIUserCustMutation__
 *
 * To run a mutation, you first call `useUpdateOneIUserCustMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneIUserCustMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneIUserCustMutation, { data, loading, error }] = useUpdateOneIUserCustMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneIUserCustMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneIUserCustMutation, UpdateOneIUserCustMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneIUserCustMutation, UpdateOneIUserCustMutationVariables>(UpdateOneIUserCustDocument, options);
      }
export type UpdateOneIUserCustMutationHookResult = ReturnType<typeof useUpdateOneIUserCustMutation>;
export type UpdateOneIUserCustMutationResult = Apollo.MutationResult<UpdateOneIUserCustMutation>;
export type UpdateOneIUserCustMutationOptions = Apollo.BaseMutationOptions<UpdateOneIUserCustMutation, UpdateOneIUserCustMutationVariables>;
export const IUserFullDocument = gql`
    query IUserFull($where: IUserWhereUniqueInput!) {
  iUser(where: $where) {
    createdAt
    customers {
      id
      dkNumber
      name
    }
    updatedAt
    salt
    roles {
      id
      name
    }
    enabled
    name
    lastName
    id
    email
  }
}
    `;

/**
 * __useIUserFullQuery__
 *
 * To run a query within a React component, call `useIUserFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useIUserFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIUserFullQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useIUserFullQuery(baseOptions: Apollo.QueryHookOptions<IUserFullQuery, IUserFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUserFullQuery, IUserFullQueryVariables>(IUserFullDocument, options);
      }
export function useIUserFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUserFullQuery, IUserFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUserFullQuery, IUserFullQueryVariables>(IUserFullDocument, options);
        }
export type IUserFullQueryHookResult = ReturnType<typeof useIUserFullQuery>;
export type IUserFullLazyQueryHookResult = ReturnType<typeof useIUserFullLazyQuery>;
export type IUserFullQueryResult = Apollo.QueryResult<IUserFullQuery, IUserFullQueryVariables>;
export const IUsersWhereDocument = gql`
    query IUsersWhere($skip: Int, $take: Int, $where: IUserWhereInput) {
  iUsers(skip: $skip, take: $take, where: $where) {
    id
    email
    createdAt
    updatedAt
    roles {
      id
      name
    }
    lastName
    name
    customers {
      id
      name
      dkNumber
    }
    enabled
  }
  aggregateIUser {
    _count {
      _all
    }
  }
}
    `;

/**
 * __useIUsersWhereQuery__
 *
 * To run a query within a React component, call `useIUsersWhereQuery` and pass it any options that fit your needs.
 * When your component renders, `useIUsersWhereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIUsersWhereQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useIUsersWhereQuery(baseOptions?: Apollo.QueryHookOptions<IUsersWhereQuery, IUsersWhereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IUsersWhereQuery, IUsersWhereQueryVariables>(IUsersWhereDocument, options);
      }
export function useIUsersWhereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IUsersWhereQuery, IUsersWhereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IUsersWhereQuery, IUsersWhereQueryVariables>(IUsersWhereDocument, options);
        }
export type IUsersWhereQueryHookResult = ReturnType<typeof useIUsersWhereQuery>;
export type IUsersWhereLazyQueryHookResult = ReturnType<typeof useIUsersWhereLazyQuery>;
export type IUsersWhereQueryResult = Apollo.QueryResult<IUsersWhereQuery, IUsersWhereQueryVariables>;