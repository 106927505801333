import React, { useState } from "react";
import { Grid, TextField, Box, Button, Paper, Alert , Select, SelectChangeEvent, MenuItem} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SearchParams } from "./Invoices";
import { ApolloError } from "@apollo/client";

type InvoiceSearchFormProps = {
  onSearch: (params: SearchParams) => void;
  searchParams: SearchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  loading: boolean;
  error: ApolloError | undefined;

};

const InvoiceSearchForm: React.FC<InvoiceSearchFormProps> = ({ onSearch, setSearchParams, searchParams, loading, error }) => {

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSearch(searchParams);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const name = event.target.name;
    if (event.target.value === "--") {
      return;
    }
    if (event.target.name === "udidCode") {
      setSearchParams({
        ...searchParams,
        [name]: event.target.value,
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "invoiceNumber" && value.trim() !== "") {
      setSearchParams({
        ...searchParams,
        invoiceNumber: value,
        dkNumber: "",
        recordLocator: '',
        dateFrom: null,
        dateTo: null,
        customer: '',
        supplier: '',
        traveler: '',
        udid: 0,
        udidCode: '',
      });
    } else {
      setSearchParams({
        ...searchParams,
        [name]: value,
      });
    }
  };

  const handleDateChange = (date: Date | null, field: 'dateFrom' | 'dateTo') => {
    if (date) {
      setSearchParams(prev => ({
        ...prev,
        [field]: date
      }));
    }
  };

  return (
    <Paper elevation={1}>

      {error && <Alert severity="error">{error.message}</Alert>}

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, bgcolor: "#DFE6F4", padding: 3 }}>

        <form noValidate autoComplete="off" onSubmit={handleSubmit}>

          <Grid container spacing={2} columns={24}>

            <Grid item sm={2}>
              <TextField
                name="invoiceNumber" // must match the name of the property in the state
                label="Invoice Number"
                variant="outlined"
                value={searchParams.invoiceNumber}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sm={3}>
              <DatePicker
                label="Check-in"
                value={searchParams.dateFrom}
                onChange={(date) => handleDateChange(date, 'dateFrom')}
              />
            </Grid>

            <Grid item sm={3}>

              <DatePicker
                label="Check-out"
                value={searchParams.dateTo}
                onChange={(date) => handleDateChange(date, 'dateTo')}
              />

            </Grid>

            <Grid item sm={2}>
            <TextField
                name="dkNumber"
                label="DK #"
                variant="outlined"
                fullWidth
                value={searchParams.dkNumber}
                onChange={handleInputChange} />
            </Grid>

            <Grid item sm={3}>
              <TextField
                name="recordLocator"
                label="Locator"
                variant="outlined"
                fullWidth
                value={searchParams.recordLocator}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={2}>
              <Select
                variant="outlined"
                required
                fullWidth
                name="udidCode"
                value={searchParams.udidCode + ""}
                onChange={handleSelectChange}
              >
                  <MenuItem value="udid01">01</MenuItem>
                  <MenuItem value="udid02">02</MenuItem>
                  <MenuItem value="udid04">03</MenuItem>
                  <MenuItem value="udid04">04</MenuItem>
                  <MenuItem value="udid05">05</MenuItem>
                  <MenuItem value="udid06">06</MenuItem>
                  <MenuItem value="udid07">07</MenuItem>
                  <MenuItem value="udid08">08</MenuItem>
                  <MenuItem value="udid09">09</MenuItem>
                  <MenuItem value="udid10">10</MenuItem>
                  <MenuItem value="udid11">11</MenuItem>
                  <MenuItem value="udid12">12</MenuItem>
                  <MenuItem value="udid14">13</MenuItem>
                  <MenuItem value="udid14">14</MenuItem>
                  <MenuItem value="udid15">15</MenuItem>
                  <MenuItem value="udid16">16</MenuItem>
                  <MenuItem value="udid17">17</MenuItem>
                  <MenuItem value="udid18">18</MenuItem>
              </Select>

            </Grid>
            <Grid item sm={3}>
              <TextField
                name="udidValue"
                label="UDID Value"
                variant="outlined"
                value={searchParams.udidValue}
                onChange={handleInputChange}
              />

            </Grid>
            <Grid item sm={3}>

            </Grid>

            <Grid item style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="submit" variant="contained" color="primary">
                Search
              </Button>
            </Grid>

            <Grid item sm={5}>
              <TextField
                name="supplier"
                label="Supplier"
                variant="outlined"
                value={searchParams.supplier}
                onChange={handleInputChange}
                fullWidth />
            </Grid>

            <Grid item sm={5}>
              <TextField
                name="traveler"
                label="Traveler"
                value={searchParams.traveler}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth />
            </Grid>
            <Grid item sm={4}>

            </Grid>



          </Grid>

        </form>
      </Box>
    </Paper>
  );
};

export default InvoiceSearchForm;
