import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  Button,
  Tabs,
  Tab,
  Divider,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Box,
  Grid,
  TextField,
  Alert,
  Typography,
} from "@mui/material";
import {
  useCustomerFullQuery,
  useCustomerUpdateOneMutation,
  useCustomerCreateOneMutation,
} from "../../../generated/graphql";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import Customer, {
  CustomerAddress,
  CustomerUDID,
} from "../../../models/Customer";
import {
  makeCreateCustomerFn,
  makeUpdateCustomerFn,
  toDBCodes,
  toUICodes,
} from "./CustomerUtils";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1, mt: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerForm: React.FC = () => {
  // Mutatio error
  const [mutationError, setMutationError] = useState<string | null>(null);
  const [mutationOk, setMutationOk] = useState<string | null>(null);

  // Using useParams hook to get the id parameter from the URL
  const { id } = useParams<{ id: string }>();
  const [updateCustomer, { loading: updateLoading, error: updateError }] =
    useCustomerUpdateOneMutation();
  const [createCustomer, { loading: createLoading, error: createError }] =
    useCustomerCreateOneMutation();

  // Load customer using graphql
  const { data, loading, error, refetch } = useCustomerFullQuery({
    variables: {
      where: { id: parseInt(id ? id : "-1") },
    },
  });

  // Customer state (create a type)
  const [customer, setCustomer] = useState<Customer>({
    id: -1,
    dkNumber: "",
    name: "",
    paymentTerm: "",
    street: "",
    city: "",
    state: "",
    country: "",
    postal: "",
    addresses: [],
    udids: [],
  });

  // List of deleted addresses
  const [deletedAddresses, setDeletedAddresses] = useState<CustomerAddress[]>(
    [],
  );
  const [deletedUDIDs, setDeletedUDIDs] = useState<CustomerUDID[]>([]);

  // If data?.customer is populated, set the customer
  useEffect(() => {
    if (data?.customer) {
      const c = toUICodes(data.customer);
      setCustomer(c);
    }
  }, [data]);

  // Handle changes to the form
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.target;
    setCustomer((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const name = event.target.name as keyof typeof customer;
    if (event.target.value === "--") {
      return;
    }
    setCustomer({ ...customer, [name]: event.target.value });
  };

  const handleAddressSelectChange = (
    index: number,
    event: SelectChangeEvent<string>,
  ) => {
    const { name, value } = event.target;

    // Update the specific address using type assertion
    const updatedAddresses = [...customer.addresses];
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      [name as keyof CustomerAddress]: value,
    };

    // Update the customer local state
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      addresses: updatedAddresses,
    }));
  };

  // Handle submit: save
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const c = toDBCodes(customer);
      // clear mutation error
      setMutationError(null);
      setMutationOk(null);
      const response = await (c.id > 0
        ? makeUpdateCustomerFn(
            c,
            updateCustomer,
            deletedAddresses,
            deletedUDIDs,
          )
        : makeCreateCustomerFn(c, createCustomer))();
      // Check for error
      if (response.errors) {
        setMutationError(response.errors[0].message);
        return;
      }
      if (response.customer) {
        const c = toUICodes(response.customer);
        setCustomer(c);
      }
      setMutationOk("Customer saved");
    } catch (error) {
      // If there's a network or other kind of error.
      setMutationError((error as Error).message);
    }
  };

  // Addresses management
  const handleAddAddress = () => {
    const code = customer.addresses.length < 1 ? "default" : "";
    setCustomer({
      ...customer,
      addresses: [
        ...customer.addresses,
        {
          id: -1,
          code: code,
          name: customer.name,
          city: customer.city,
          state: customer.state,
          street: customer.street,
          country: customer.country,
          postal: customer.postal,
        },
      ],
    });
  };

  const handleDeleteAddress = (index: number) => {
    const addressToDelete = customer.addresses[index];
    // Add the address to the deletedAddresses list
    if (addressToDelete.id > 0) {
      setDeletedAddresses((prevDeleted) => [...prevDeleted, addressToDelete]);
    }

    const updatedAddresses = customer.addresses.filter(
      (_, idx) => idx !== index,
    );
    // Update the customer state with the new list of addresses
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      addresses: updatedAddresses,
    }));
  };

  const handleAddressInputChange = (
    index: number,
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = event.target;

    // Update the specific address using type assertion
    const updatedAddresses = [...customer.addresses];
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      [name as keyof CustomerAddress]: value,
    };

    // Update the customer local state
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      addresses: updatedAddresses,
    }));
  };

  /// UDIDs management
  const handleAddUDID = () => {
    setCustomer({
      ...customer,
      udids: [
        ...customer.udids,
        {
          id: -1,
          displayName: "",
          udid: "",
        },
      ],
    });
  };

  const handleDeleteUDID = (index: number) => {
    const udidsToDelete = customer.udids[index];
    // Add the address to the deletedAddresses list
    if (udidsToDelete.id > 0) {
      setDeletedUDIDs((prevDeleted) => [...prevDeleted, udidsToDelete]);
    }

    const updatedUDIDs = customer.udids.filter((_, idx) => idx !== index);
    // Update the customer state with the new list of addresses
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      udids: updatedUDIDs,
    }));
  };

  const handleUDIDInputChange = (
    index: number,
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = event.target;

    // Update the specific address using type assertion
    const updatedUDIDs = [...customer.udids];
    updatedUDIDs[index] = {
      ...updatedUDIDs[index],
      [name as keyof CustomerUDID]: value,
    };

    // Update the customer local state
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      udids: updatedUDIDs,
    }));
  };

  // Tab management
  const [tab1Value, setTab1Value] = React.useState(0);
  const handleTab1Change = (event: React.SyntheticEvent, newValue: number) => {
    setTab1Value(newValue);
  };

  const [tab2Value, setTab2Value] = React.useState(0);
  const handleTab2Change = (event: React.SyntheticEvent, newValue: number) => {
    setTab2Value(newValue);
  };

  const fieldIsMissing = (field: string) => {
    return mutationError !== null && customer[field] === "";
  };
  const fieldRequiredMsg = (field: string) => {
    return mutationError !== null && customer[field] === "" ? "Required" : "";
  };
  const addressFieldIsMissing = (field: string, idx: number) => {
    return mutationError !== null && customer.addresses[idx][field] === "";
  };
  const addressFieldRequiredMsg = (field: string, idx: number) => {
    return mutationError !== null &&
      customer.addresses[idx] &&
      customer.addresses[idx][field] === ""
      ? "Required"
      : "";
  };
  const udidFieldIsMissing = (field: string, idx: number) => {
    return mutationError !== null && customer.udids[idx][field] === "";
  };
  const udidFieldRequiredMsg = (field: string, idx: number) => {
    return mutationError !== null &&
      customer.udids[idx] &&
      customer.udids[idx][field] === ""
      ? "Required"
      : "";
  };

  return (
    <Box sx={{ margin: 2, pt: 1, minHeight: "80vh" }}>
      {!(loading || updateLoading || createLoading) && (
        <>
          <Typography variant="h3" component="h1" gutterBottom>
            Company : {customer.id > 0 ? customer.dkNumber : "New"}
          </Typography>
          <Divider sx={{ my: 2, bgcolor: "secondary.main" }} />
          {error && <Alert severity="error">{error.message}</Alert>}
          {mutationOk && <Alert severity="success">{mutationOk}</Alert>}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container>
              <Grid item sm={3}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tab1Value}
                    onChange={handleTab1Change}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Details" {...a11yProps(0)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tab1Value} index={0}>
                  <Box>
                    <Grid container>
                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          variant="outlined"
                          label="DK #"
                          required
                          placeholder="Enter DK #"
                          name="dkNumber"
                          value={customer.dkNumber}
                          error={fieldIsMissing("dkNumber")}
                          helperText={fieldRequiredMsg("dkNumber")}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          variant="outlined"
                          required
                          label="Name"
                          placeholder="Name"
                          name="name"
                          value={customer.name}
                          onChange={handleInputChange}
                          error={fieldIsMissing("name")}
                          helperText={fieldRequiredMsg("name")}
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          variant="outlined"
                          required
                          label="Payment term"
                          placeholder="Payment term"
                          name="paymentTerm"
                          value={customer.paymentTerm}
                          onChange={handleInputChange}
                          error={fieldIsMissing("paymentTerm")}
                          helperText={fieldRequiredMsg("payemtTerm")}
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          label="Street"
                          placeholder="Enter street"
                          name="street"
                          value={customer.street}
                          error={fieldIsMissing("street")}
                          helperText={fieldRequiredMsg("street")}
                          onChange={handleInputChange}
                        />
                      </Grid>

                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <Grid item sm={12}>
                          <TextField
                            variant="outlined"
                            required
                            label="City"
                            placeholder="City"
                            name="city"
                            value={customer.city}
                            onChange={handleInputChange}
                            error={fieldIsMissing("city")}
                            helperText={fieldRequiredMsg("city")}
                            fullWidth
                          />
                        </Grid>
                        <Grid item sm={12} sx={{ mt: 1, mb: 1 }}></Grid>
                        <Grid container alignItems={"center"}>
                          <Grid item sm={3}>
                            <TextField
                              variant="outlined"
                              required
                              label="State"
                              placeholder="State"
                              name="state"
                              value={customer.state}
                              onChange={handleInputChange}
                              error={fieldIsMissing("state")}
                              helperText={fieldRequiredMsg("state")}
                              fullWidth
                            />
                          </Grid>
                          <Grid item sm={3}></Grid>
                          <Grid item sm={6}>
                            {selectCountry(
                              customer.country,
                              handleSelectChange,
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} sx={{ mt: 1, mb: 1 }}>
                        <TextField
                          variant="outlined"
                          required
                          label="Postal Code"
                          placeholder="Postal Code"
                          name="postal"
                          value={customer.postal}
                          onChange={handleInputChange}
                          error={fieldIsMissing("postal")}
                          helperText={fieldRequiredMsg("postal")}
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Box>
                </CustomTabPanel>
              </Grid>
              <Grid item sm={9}>
                <Box sx={{ ml: 5, borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tab2Value}
                    onChange={handleTab2Change}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Billing Addresses" {...a11yProps(0)} />
                    <Tab label="UDID Codes" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tab2Value} index={0}>
                  <Box>
                    {customer.addresses.map((address, index) => (
                      <Grid
                        container
                        columns={36}
                        sx={{ alignItems: "top", justifyContent: "center" }}
                        key={index}
                      >
                        <Grid item sm={3} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          <TextField
                            variant="outlined"
                            label="Code"
                            required
                            placeholder="Code"
                            name="code"
                            value={address.code}
                            fullWidth
                            error={addressFieldIsMissing("code", index)}
                            helperText={addressFieldRequiredMsg("code", index)}
                            onChange={(e) => handleAddressInputChange(index, e)}
                          />
                        </Grid>
                        <Grid item sm={10} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          <TextField
                            variant="outlined"
                            required
                            label="Name"
                            placeholder="Name"
                            name="name"
                            value={address.name}
                            fullWidth
                            error={addressFieldIsMissing("name", index)}
                            helperText={addressFieldRequiredMsg("name", index)}
                            onChange={(e) => handleAddressInputChange(index, e)}
                          />
                        </Grid>
                        <Grid item sm={6} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          <TextField
                            variant="outlined"
                            required
                            label="Street"
                            placeholder="Street"
                            name="street"
                            value={address.street}
                            fullWidth
                            error={addressFieldIsMissing("street", index)}
                            helperText={addressFieldRequiredMsg(
                              "street",
                              index,
                            )}
                            onChange={(e) => handleAddressInputChange(index, e)}
                          />
                        </Grid>
                        <Grid item sm={5} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          <TextField
                            variant="outlined"
                            required
                            label="City"
                            placeholder="City"
                            name="city"
                            value={address.city}
                            fullWidth
                            error={addressFieldIsMissing("city", index)}
                            helperText={addressFieldRequiredMsg("city", index)}
                            onChange={(e) => handleAddressInputChange(index, e)}
                          />
                        </Grid>
                        <Grid item sm={2} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          <TextField
                            variant="outlined"
                            required
                            label="State"
                            placeholder="State"
                            name="state"
                            value={address.state}
                            fullWidth
                            error={addressFieldIsMissing("state", index)}
                            helperText={addressFieldRequiredMsg("state", index)}
                            onChange={(e) => handleAddressInputChange(index, e)}
                          />
                        </Grid>
                        <Grid item sm={4} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          {selectCountry(address.country, (e) =>
                            handleAddressSelectChange(index, e),
                          )}
                        </Grid>
                        <Grid item sm={3} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            required
                            label="Postal Code"
                            placeholder="Enter postal code"
                            name="postal"
                            value={address.postal}
                            error={addressFieldIsMissing("postal", index)}
                            helperText={addressFieldRequiredMsg(
                              "postal",
                              index,
                            )}
                            onChange={(e) => handleAddressInputChange(index, e)}
                          />
                        </Grid>
                        <Grid item sm={1} sx={{ alignItems: "baseline" }}>
                          <Tooltip title="Delete Address ...">
                            <IconButton
                              onClick={() => handleDeleteAddress(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid container sx={{ m: 1 }}>
                      <Grid>
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ bgcolor: "#C0B7A4", color: "white" }}
                          onClick={handleAddAddress}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tab2Value} index={1}>
                  <Box>
                    {customer.udids.map((udid, index) => (
                      <Grid
                        container
                        columns={18}
                        sx={{ alignItems: "center", justifyContent: "left" }}
                        key={udid.id || index}
                      >
                        {" "}
                        <Grid item sm={1} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          <TextField
                            variant="outlined"
                            required
                            label="Code"
                            placeholder="Code"
                            inputProps={{ inputMode: "numeric", maxLength: 2 }}
                            name="udid"
                            value={udid.udid}
                            fullWidth
                            error={udidFieldIsMissing("udid", index)}
                            helperText={udidFieldRequiredMsg("udid", index)}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                /^\d{1,2}$/.test(e.target.value)
                              ) {
                                handleUDIDInputChange(index, e);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} sx={{ mt: 1, mb: 1, mr: 1 }}>
                          <TextField
                            variant="outlined"
                            required
                            label="Invoice Label"
                            placeholder="Invoice Label"
                            name="displayName"
                            value={udid.displayName}
                            fullWidth
                            error={udidFieldIsMissing("displayName", index)}
                            helperText={udidFieldRequiredMsg(
                              "displayName",
                              index,
                            )}
                            onChange={(e) => handleUDIDInputChange(index, e)}
                          />
                        </Grid>
                        <Grid item sm={1} sx={{ alignItems: "baseline" }}>
                          <Tooltip title="Delete UDID ...">
                            <IconButton onClick={() => handleDeleteUDID(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid></Grid>
                      </Grid>
                    ))}
                    <Grid container sx={{ m: 1 }}>
                      <Grid>
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ bgcolor: "#C0B7A4", color: "white" }}
                          onClick={handleAddUDID}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CustomTabPanel>
              </Grid>
            </Grid>
          </form>
        </>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || updateLoading || createLoading}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default CustomerForm;

const selectCountry = (
  v: string,
  chg: {
    (event: SelectChangeEvent<string>): void;
    (arg0: SelectChangeEvent<any>): void;
  },
) => {
  return (
    <Select
      variant="outlined"
      required
      fullWidth
      name="country"
      value={v}
      onChange={(e) => chg(e)}
    >
      <MenuItem value="CA">Canada</MenuItem>
      <MenuItem value="US">United Sates</MenuItem>
      <MenuItem value="--">
        <Divider></Divider>
      </MenuItem>
      <MenuItem value="AF">Afghanistan</MenuItem>
      <MenuItem value="AX">Åland Islands</MenuItem>
      <MenuItem value="AL">Albania</MenuItem>
      <MenuItem value="DZ">Algeria</MenuItem>
      <MenuItem value="AS">American Samoa</MenuItem>
      <MenuItem value="AD">Andorra</MenuItem>
      <MenuItem value="AO">Angola</MenuItem>
      <MenuItem value="AI">Anguilla</MenuItem>
      <MenuItem value="AQ">Antarctica</MenuItem>
      <MenuItem value="AG">Antigua and Barbuda</MenuItem>
      <MenuItem value="AR">Argentina</MenuItem>
      <MenuItem value="AM">Armenia</MenuItem>
      <MenuItem value="AW">Aruba</MenuItem>
      <MenuItem value="AU">Australia</MenuItem>
      <MenuItem value="AT">Austria</MenuItem>
      <MenuItem value="AZ">Azerbaijan</MenuItem>
      <MenuItem value="BS">Bahamas</MenuItem>
      <MenuItem value="BH">Bahrain</MenuItem>
      <MenuItem value="BD">Bangladesh</MenuItem>
      <MenuItem value="BB">Barbados</MenuItem>
      <MenuItem value="BY">Belarus</MenuItem>
      <MenuItem value="BE">Belgium</MenuItem>
      <MenuItem value="BZ">Belize</MenuItem>
      <MenuItem value="BJ">Benin</MenuItem>
      <MenuItem value="BM">Bermuda</MenuItem>
      <MenuItem value="BT">Bhutan</MenuItem>
      <MenuItem value="BO">Bolivia, Plurinational State of</MenuItem>
      <MenuItem value="BQ">Bonaire, Sint Eustatius and Saba</MenuItem>
      <MenuItem value="BA">Bosnia and Herzegovina</MenuItem>
      <MenuItem value="BW">Botswana</MenuItem>
      <MenuItem value="BV">Bouvet Island</MenuItem>
      <MenuItem value="BR">Brazil</MenuItem>
      <MenuItem value="IO">British Indian Ocean Territory</MenuItem>
      <MenuItem value="BN">Brunei Darussalam</MenuItem>
      <MenuItem value="BG">Bulgaria</MenuItem>
      <MenuItem value="BF">Burkina Faso</MenuItem>
      <MenuItem value="BI">Burundi</MenuItem>
      <MenuItem value="KH">Cambodia</MenuItem>
      <MenuItem value="CM">Cameroon</MenuItem>

      <MenuItem value="CV">Cape Verde</MenuItem>
      <MenuItem value="KY">Cayman Islands</MenuItem>
      <MenuItem value="CF">Central African Republic</MenuItem>
      <MenuItem value="TD">Chad</MenuItem>
      <MenuItem value="CL">Chile</MenuItem>
      <MenuItem value="CN">China</MenuItem>
      <MenuItem value="CX">Christmas Island</MenuItem>
      <MenuItem value="CC">Cocos (Keeling) Islands</MenuItem>
      <MenuItem value="CO">Colombia</MenuItem>
      <MenuItem value="KM">Comoros</MenuItem>
      <MenuItem value="CG">Congo</MenuItem>
      <MenuItem value="CD">Congo, the Democratic Republic of the</MenuItem>
      <MenuItem value="CK">Cook Islands</MenuItem>
      <MenuItem value="CR">Costa Rica</MenuItem>
      <MenuItem value="CI">Côte d'Ivoire</MenuItem>
      <MenuItem value="HR">Croatia</MenuItem>
      <MenuItem value="CU">Cuba</MenuItem>
      <MenuItem value="CW">Curaçao</MenuItem>
      <MenuItem value="CY">Cyprus</MenuItem>
      <MenuItem value="CZ">Czech Republic</MenuItem>
      <MenuItem value="DK">Denmark</MenuItem>
      <MenuItem value="DJ">Djibouti</MenuItem>
      <MenuItem value="DM">Dominica</MenuItem>
      <MenuItem value="DO">Dominican Republic</MenuItem>
      <MenuItem value="EC">Ecuador</MenuItem>
      <MenuItem value="EG">Egypt</MenuItem>
      <MenuItem value="SV">El Salvador</MenuItem>
      <MenuItem value="GQ">Equatorial Guinea</MenuItem>
      <MenuItem value="ER">Eritrea</MenuItem>
      <MenuItem value="EE">Estonia</MenuItem>
      <MenuItem value="ET">Ethiopia</MenuItem>
      <MenuItem value="FK">Falkland Islands (Malvinas)</MenuItem>
      <MenuItem value="FO">Faroe Islands</MenuItem>
      <MenuItem value="FJ">Fiji</MenuItem>
      <MenuItem value="FI">Finland</MenuItem>
      <MenuItem value="FR">France</MenuItem>
      <MenuItem value="GF">French Guiana</MenuItem>
      <MenuItem value="PF">French Polynesia</MenuItem>
      <MenuItem value="TF">French Southern Territories</MenuItem>
      <MenuItem value="GA">Gabon</MenuItem>
      <MenuItem value="GM">Gambia</MenuItem>
      <MenuItem value="GE">Georgia</MenuItem>
      <MenuItem value="DE">Germany</MenuItem>
      <MenuItem value="GH">Ghana</MenuItem>
      <MenuItem value="GI">Gibraltar</MenuItem>
      <MenuItem value="GR">Greece</MenuItem>
      <MenuItem value="GL">Greenland</MenuItem>
      <MenuItem value="GD">Grenada</MenuItem>
      <MenuItem value="GP">Guadeloupe</MenuItem>
      <MenuItem value="GU">Guam</MenuItem>
      <MenuItem value="GT">Guatemala</MenuItem>
      <MenuItem value="GG">Guernsey</MenuItem>
      <MenuItem value="GN">Guinea</MenuItem>
      <MenuItem value="GW">Guinea-Bissau</MenuItem>
      <MenuItem value="GY">Guyana</MenuItem>
      <MenuItem value="HT">Haiti</MenuItem>
      <MenuItem value="HM">Heard Island and McDonald Islands</MenuItem>
      <MenuItem value="VA">Holy See (Vatican City State)</MenuItem>
      <MenuItem value="HN">Honduras</MenuItem>
      <MenuItem value="HK">Hong Kong</MenuItem>
      <MenuItem value="HU">Hungary</MenuItem>
      <MenuItem value="IS">Iceland</MenuItem>
      <MenuItem value="IN">India</MenuItem>
      <MenuItem value="ID">Indonesia</MenuItem>
      <MenuItem value="IR">Iran, Islamic Republic of</MenuItem>
      <MenuItem value="IQ">Iraq</MenuItem>
      <MenuItem value="IE">Ireland</MenuItem>
      <MenuItem value="IM">Isle of Man</MenuItem>
      <MenuItem value="IL">Israel</MenuItem>
      <MenuItem value="IT">Italy</MenuItem>
      <MenuItem value="JM">Jamaica</MenuItem>
      <MenuItem value="JP">Japan</MenuItem>
      <MenuItem value="JE">Jersey</MenuItem>
      <MenuItem value="JO">Jordan</MenuItem>
      <MenuItem value="KZ">Kazakhstan</MenuItem>
      <MenuItem value="KE">Kenya</MenuItem>
      <MenuItem value="KI">Kiribati</MenuItem>
      <MenuItem value="KP">Korea, Democratic People's Republic of</MenuItem>
      <MenuItem value="KR">Korea, Republic of</MenuItem>
      <MenuItem value="KW">Kuwait</MenuItem>
      <MenuItem value="KG">Kyrgyzstan</MenuItem>
      <MenuItem value="LA">Lao People's Democratic Republic</MenuItem>
      <MenuItem value="LV">Latvia</MenuItem>
      <MenuItem value="LB">Lebanon</MenuItem>
      <MenuItem value="LS">Lesotho</MenuItem>
      <MenuItem value="LR">Liberia</MenuItem>
      <MenuItem value="LY">Libya</MenuItem>
      <MenuItem value="LI">Liechtenstein</MenuItem>
      <MenuItem value="LT">Lithuania</MenuItem>
      <MenuItem value="LU">Luxembourg</MenuItem>
      <MenuItem value="MO">Macao</MenuItem>
      <MenuItem value="MK">Macedonia, the Former Yugoslav Republic of</MenuItem>
      <MenuItem value="MG">Madagascar</MenuItem>
      <MenuItem value="MW">Malawi</MenuItem>
      <MenuItem value="MY">Malaysia</MenuItem>
      <MenuItem value="MV">Maldives</MenuItem>
      <MenuItem value="ML">Mali</MenuItem>
      <MenuItem value="MT">Malta</MenuItem>
      <MenuItem value="MH">Marshall Islands</MenuItem>
      <MenuItem value="MQ">Martinique</MenuItem>
      <MenuItem value="MR">Mauritania</MenuItem>
      <MenuItem value="MU">Mauritius</MenuItem>
      <MenuItem value="YT">Mayotte</MenuItem>
      <MenuItem value="MX">Mexico</MenuItem>
      <MenuItem value="FM">Micronesia, Federated States of</MenuItem>
      <MenuItem value="MD">Moldova, Republic of</MenuItem>
      <MenuItem value="MC">Monaco</MenuItem>
      <MenuItem value="MN">Mongolia</MenuItem>
      <MenuItem value="ME">Montenegro</MenuItem>
      <MenuItem value="MS">Montserrat</MenuItem>
      <MenuItem value="MA">Morocco</MenuItem>
      <MenuItem value="MZ">Mozambique</MenuItem>
      <MenuItem value="MM">Myanmar</MenuItem>
      <MenuItem value="NA">Namibia</MenuItem>
      <MenuItem value="NR">Nauru</MenuItem>
      <MenuItem value="NP">Nepal</MenuItem>
      <MenuItem value="NL">Netherlands</MenuItem>
      <MenuItem value="NC">New Caledonia</MenuItem>
      <MenuItem value="NZ">New Zealand</MenuItem>
      <MenuItem value="NI">Nicaragua</MenuItem>
      <MenuItem value="NE">Niger</MenuItem>
      <MenuItem value="NG">Nigeria</MenuItem>
      <MenuItem value="NU">Niue</MenuItem>
      <MenuItem value="NF">Norfolk Island</MenuItem>
      <MenuItem value="MP">Northern Mariana Islands</MenuItem>
      <MenuItem value="NO">Norway</MenuItem>
      <MenuItem value="OM">Oman</MenuItem>
      <MenuItem value="PK">Pakistan</MenuItem>
      <MenuItem value="PW">Palau</MenuItem>
      <MenuItem value="PS">Palestine, State of</MenuItem>
      <MenuItem value="PA">Panama</MenuItem>
      <MenuItem value="PG">Papua New Guinea</MenuItem>
      <MenuItem value="PY">Paraguay</MenuItem>
      <MenuItem value="PE">Peru</MenuItem>
      <MenuItem value="PH">Philippines</MenuItem>
      <MenuItem value="PN">Pitcairn</MenuItem>
      <MenuItem value="PL">Poland</MenuItem>
      <MenuItem value="PT">Portugal</MenuItem>
      <MenuItem value="PR">Puerto Rico</MenuItem>
      <MenuItem value="QA">Qatar</MenuItem>
      <MenuItem value="RE">Réunion</MenuItem>
      <MenuItem value="RO">Romania</MenuItem>
      <MenuItem value="RU">Russian Federation</MenuItem>
      <MenuItem value="RW">Rwanda</MenuItem>
      <MenuItem value="BL">Saint Barthélemy</MenuItem>
      <MenuItem value="SH">
        Saint Helena, Ascension and Tristan da Cunha
      </MenuItem>
      <MenuItem value="KN">Saint Kitts and Nevis</MenuItem>
      <MenuItem value="LC">Saint Lucia</MenuItem>
      <MenuItem value="MF">Saint Martin (French part)</MenuItem>
      <MenuItem value="PM">Saint Pierre and Miquelon</MenuItem>
      <MenuItem value="VC">Saint Vincent and the Grenadines</MenuItem>
      <MenuItem value="WS">Samoa</MenuItem>
      <MenuItem value="SM">San Marino</MenuItem>
      <MenuItem value="ST">Sao Tome and Principe</MenuItem>
      <MenuItem value="SA">Saudi Arabia</MenuItem>
      <MenuItem value="SN">Senegal</MenuItem>
      <MenuItem value="RS">Serbia</MenuItem>
      <MenuItem value="SC">Seychelles</MenuItem>
      <MenuItem value="SL">Sierra Leone</MenuItem>
      <MenuItem value="SG">Singapore</MenuItem>
      <MenuItem value="SX">Sint Maarten (Dutch part)</MenuItem>
      <MenuItem value="SK">Slovakia</MenuItem>
      <MenuItem value="SI">Slovenia</MenuItem>
      <MenuItem value="SB">Solomon Islands</MenuItem>
      <MenuItem value="SO">Somalia</MenuItem>
      <MenuItem value="ZA">South Africa</MenuItem>
      <MenuItem value="GS">
        South Georgia and the South Sandwich Islands
      </MenuItem>
      <MenuItem value="SS">South Sudan</MenuItem>
      <MenuItem value="ES">Spain</MenuItem>
      <MenuItem value="LK">Sri Lanka</MenuItem>
      <MenuItem value="SD">Sudan</MenuItem>
      <MenuItem value="SR">Suriname</MenuItem>
      <MenuItem value="SJ">Svalbard and Jan Mayen</MenuItem>
      <MenuItem value="SZ">Swaziland</MenuItem>
      <MenuItem value="SE">Sweden</MenuItem>
      <MenuItem value="CH">Switzerland</MenuItem>
      <MenuItem value="SY">Syrian Arab Republic</MenuItem>
      <MenuItem value="TW">Taiwan, Province of China</MenuItem>
      <MenuItem value="TJ">Tajikistan</MenuItem>
      <MenuItem value="TZ">Tanzania, United Republic of</MenuItem>
      <MenuItem value="TH">Thailand</MenuItem>
      <MenuItem value="TL">Timor-Leste</MenuItem>
      <MenuItem value="TG">Togo</MenuItem>
      <MenuItem value="TK">Tokelau</MenuItem>
      <MenuItem value="TO">Tonga</MenuItem>
      <MenuItem value="TT">Trinidad and Tobago</MenuItem>
      <MenuItem value="TN">Tunisia</MenuItem>
      <MenuItem value="TR">Turkey</MenuItem>
      <MenuItem value="TM">Turkmenistan</MenuItem>
      <MenuItem value="TC">Turks and Caicos Islands</MenuItem>
      <MenuItem value="TV">Tuvalu</MenuItem>
      <MenuItem value="UG">Uganda</MenuItem>
      <MenuItem value="UA">Ukraine</MenuItem>
      <MenuItem value="AE">United Arab Emirates</MenuItem>
      <MenuItem value="GB">United Kingdom</MenuItem>

      <MenuItem value="UM">United States Minor Outlying Islands</MenuItem>
      <MenuItem value="UY">Uruguay</MenuItem>
      <MenuItem value="UZ">Uzbekistan</MenuItem>
      <MenuItem value="VU">Vanuatu</MenuItem>
      <MenuItem value="VE">Venezuela, Bolivarian Republic of</MenuItem>
      <MenuItem value="VN">Viet Nam</MenuItem>
      <MenuItem value="VG">Virgin Islands, British</MenuItem>
      <MenuItem value="VI">Virgin Islands, U.S.</MenuItem>
      <MenuItem value="WF">Wallis and Futuna</MenuItem>
      <MenuItem value="EH">Western Sahara</MenuItem>
      <MenuItem value="YE">Yemen</MenuItem>
      <MenuItem value="ZM">Zambia</MenuItem>
    </Select>
  );
};
