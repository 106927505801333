import React, { useState } from "react";
import { Grid, TextField, Box, Button, Paper, Alert, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SearchParams } from "./Invoices";
import { ApolloError } from "@apollo/client";
import { useCustomerUdiDsQuery, useCustomersUdiDsAllQuery } from "../../generated/graphql";
type InvoiceCustSearchFormProps = {
  onSearch: (params: SearchParams) => void;
  searchParams: SearchParams;
  dkNumbers: string[];
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  loading: boolean;
  error: ApolloError | undefined;

};

const InvoiceCustSearchForm: React.FC<InvoiceCustSearchFormProps> = ({ onSearch, setSearchParams, dkNumbers, searchParams, loading, error }) => {

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSearch(searchParams);
  };

  const cData = useCustomersUdiDsAllQuery(
    {
      variables: {
        where: {
          dkNumber: { in: dkNumbers }
        },
      },
    }
  );

  const customers = cData.data?.customers;
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value } = event.target;

    if (name === "invoiceNumber" && value.trim() !== "") {
      setSearchParams({
        ...searchParams,
        invoiceNumber: value,
        recordLocator: '',
        dateFrom: null,
        dateTo: null,
        customer: '',
        supplier: '',
        traveler: ''
      });
    } else {
      setSearchParams({
        ...searchParams,
        [name]: value,
      });
    }
  };

  const handleDateChange = (date: Date | null, field: 'dateFrom' | 'dateTo') => {
    if (date) {
      setSearchParams(prev => ({
        ...prev,
        [field]: date
      }));
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const name = event.target.name as keyof typeof customers;
    if (event.target.value === "--") {
      return;
    }
    if (event.target.name === "udid") {
      setSearchParams({
        ...searchParams,
        [name]: Number(event.target.value),
      });
    }
  };

  const renderUDIDs = () => {

    const flatUdids = customers?.reduce((acc, customer) => {
      return acc.concat(
        customer.udids.map(udid => ({...udid, dkNumber : customer.dkNumber}))
      );
    }, [] as Array<{ dkNumber: string; displayName: string; id: number }>);

    return flatUdids?.map((udid, index) => (
      <MenuItem key={index} value={udid.id}>
        {udid.displayName + " [" + udid.dkNumber + "] "}
      </MenuItem>
    ));
  };

  return (
    <Paper elevation={1}>

      {error && <Alert severity="error">{error.message}</Alert>}

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, bgcolor: "#DFE6F4", padding: 3 }}>

        <form noValidate autoComplete="off" onSubmit={handleSubmit}>

          <Grid container spacing={2}>

            <Grid item sm={1}>
              <TextField
                name="invoiceNumber" // must match the name of the property in the state
                label="Invoice #"
                variant="outlined"
                value={searchParams.invoiceNumber}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sm={2}>
              <DatePicker
                label="Check-in"
                value={searchParams.dateFrom}
                onChange={(date) => handleDateChange(date, 'dateFrom')}
              />
            </Grid>

            <Grid item sm={2}>

              <DatePicker
                label="Check-out"
                value={searchParams.dateTo}
                onChange={(date) => handleDateChange(date, 'dateTo')}
              />

            </Grid>

            <Grid item sm={2}>
              <TextField
                name="recordLocator"
                label="Locator"
                variant="outlined"
                fullWidth
                value={searchParams.recordLocator}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={2}>
              <Select
                variant="outlined"
                required
                fullWidth
                name="udid"
                value={searchParams.udid + ""}
                onChange={handleSelectChange}
              >

                {renderUDIDs()}

              </Select>

            </Grid>
            <Grid item sm={2}>
              <TextField
                name="udidValue"
                label="Value"
                variant="outlined"
                value={searchParams.udidValue}
                onChange={handleInputChange}
              />

            </Grid>

            <Grid item style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="submit" variant="contained" color="primary">
                Search
              </Button>
            </Grid>

            <Grid item sm={3}>
              <TextField
                name="supplier"
                label="Supplier"
                variant="outlined"
                value={searchParams.supplier}
                onChange={handleInputChange}
                fullWidth />
            </Grid>

            <Grid item sm={4}>
              <TextField
                name="traveler"
                label="Traveler"
                value={searchParams.traveler}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth />
            </Grid>
            <Grid item sm={4}>

            </Grid>

          </Grid>

        </form>
      </Box>
    </Paper>
  );
};

export default InvoiceCustSearchForm;
