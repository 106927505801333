import { useState } from 'react';
import { useInvoicesWhereQuery, InvoiceWhereInput, useCustomersUdiDsQuery, useCustomerUdiDsQuery } from '../../generated/graphql';

import { Backdrop, Divider, Box, Typography, CircularProgress } from '@mui/material';
import InvoiceSearchForm from './InvoiceSearchForm';
import InvoiceTable from './InvoiceTable';
import dayjs from 'dayjs';

import { useAuthnIsCustomer } from "../../hooks/Authorization";
import InvoiceCustSearchForm from './InvoiceCustSearchForm';

const PAGE_SIZE = 50; // change this to your desired page size

type SearchParams = {
  invoiceNumber: string | null;
  dkNumber: string | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  customer: string | null;
  supplier: string | null;
  traveler: string | null;
  recordLocator: string | null;
  udid: number | null;
  udidCode: string | null;
  udidValue: string | null;
};

const Invoices = () => {

  // DK # is only available to customers
  const dkNumbers = useAuthnIsCustomer();
  const currentDkNumbers = dkNumbers();
  const isCustomer = currentDkNumbers && currentDkNumbers.length > 0 ? true : false;

  const [searchParams, setSearchParams] = useState<SearchParams>({
    invoiceNumber: '',
    dkNumber: "",
    dateFrom: dayjs().subtract(30, 'day').toDate(), // first day of the current month
    dateTo: dayjs().toDate(), // today
    customer: '',
    supplier: '',
    traveler: '',
    recordLocator: '',
    udid: 0,
    udidCode: '',
    udidValue: ''
  });

  // This state will hold the submitted search params
  const [submittedParams, setSubmittedParams] = useState<SearchParams>({
    invoiceNumber: '',
    dkNumber: "",
    dateFrom: dayjs().subtract(30, 'day').toDate(),
    dateTo: dayjs().toDate(),
    customer: '',
    supplier: '',
    traveler: '',
    recordLocator: '',
    udid: 0,
    udidCode: '',
    udidValue: ''
  });

  const [page, setPage] = useState(1);

  const handleSearch = (params: SearchParams) => {
    // Only update submittedParams here, not searchParams
    setPage(1);
    setSubmittedParams(params);
  };

  const hasParams = (params: SearchParams) => {
    // return true if any of the string params is not null or empty
    if (params.invoiceNumber !== null && params.invoiceNumber !== '') return true;
    if (params.dkNumber !== null && params.dkNumber !== '') return true;
    if (params.customer !== null && params.customer !== '') return true;
    if (params.supplier !== null && params.supplier !== '') return true;
    if (params.traveler !== null && params.traveler !== '') return true;
    if (params.recordLocator !== null && params.recordLocator !== '') return true;

    if (params.dateFrom !== null) return true;
    if (params.dateTo !== null) return true;

    return false;

  }

  const udids = useCustomersUdiDsQuery({
    variables: {
      where: {
        udid: { equals: submittedParams.udidCode }
      }
    }
  })

  const buildWhereClause = (params: SearchParams) => {

    const whereClause: Partial<InvoiceWhereInput> = {};

    let combinedDKs: string[];
    if (currentDkNumbers && searchParams.dkNumber) {
      combinedDKs = [...currentDkNumbers, searchParams.dkNumber];
    } else if (currentDkNumbers) {
      combinedDKs = [...currentDkNumbers];
    } else if (searchParams.dkNumber) {
      combinedDKs = [searchParams.dkNumber];
    } else {
      combinedDKs = [];
    }

    if (combinedDKs.length > 0) {
      whereClause.dkNumber = { in: combinedDKs };
    }

    if (params.invoiceNumber) {
      const invoiceNumber = Number(params.invoiceNumber);
      if (!isNaN(invoiceNumber)) {
        whereClause.number = { equals: invoiceNumber };
      }
    }

    if (params.udid && params.udidValue) {
      whereClause.udids = whereClause.udids ?? {};
      whereClause.udids.some = {
        value: { contains: params.udidValue },
        customerUDIDId: { equals: params.udid }
      }
    }

    if (params.udidCode && params.udidValue) {
      const udidIds = udids.data?.customers.reduce<number[]>((acc, c) => acc.concat(c.udids.map((udid) => udid.id ?? 0)), []);

      whereClause.udids = whereClause.udids ?? {};
      whereClause.udids.some = {
        value: { contains: params.udidValue },
        customerUDIDId: { in: udidIds }
      }
    }

    if (params.traveler) {
      whereClause.lines = whereClause.lines ?? {};
      whereClause.lines.some = whereClause.lines.some ?? {};
      whereClause.lines.some.traveler = { contains: params.traveler };
    }

    if (params.dateFrom && params.dateTo) {

      whereClause.lines = whereClause.lines ?? {};
      whereClause.lines.some = whereClause.lines.some ?? {};
      whereClause.lines.some.AND = [
        { checkIn: { gte: params.dateFrom } },
        { checkOut: { lte: params.dateTo } }
      ];

    } else if (params.dateTo) {
      whereClause.createdAt = { lte: params.dateTo };
    } else if (params.dateFrom) {
      whereClause.createdAt = { gte: params.dateFrom };
    }

    if (params.supplier) {
      whereClause.supplier = { contains: params.supplier };
    }

    if (params.recordLocator) {
      whereClause.recordLocator = { startsWith: params.recordLocator };
    }
    console.log(JSON.stringify(whereClause));
    return whereClause;
  }

  const loadNextPage = () => {

    if (page === totalPages) return;

    fetchMore({
      variables: {
        skip: (page - 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page + 1);
  };

  const loadPrevPage = () => {
    if (page === 1) return;
    fetchMore({
      variables: {
        skip: (page - 1) * PAGE_SIZE,
        take: PAGE_SIZE,
      },
    });
    setPage(page - 1);
  };

  // Use submittedParams here, not searchParams
  const { data, loading, error, fetchMore } = useInvoicesWhereQuery({
    variables: {
      where: buildWhereClause(submittedParams),
      skip: (page - 1) * PAGE_SIZE,
      take: PAGE_SIZE,
    },

    skip: hasParams(submittedParams) === false,
  });

  // Assuming data?.aggregateInvoice?._count?.number contains the total count of invoices
  const totalCount = data?.aggregateInvoice?._count?.number || 0;

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / PAGE_SIZE);

  return (

    <Box sx={{ margin: 2, pt: 3, p: 1, minHeight: '80vh' }}>

      <Typography variant="h3" component="h1" gutterBottom>
        Invoices: Search
      </Typography>
      <Divider sx={{ my: 2, bgcolor: 'secondary.main' }} />

      {!dkNumbers() &&
        <InvoiceSearchForm onSearch={handleSearch}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          loading={loading}
          error={error} />}

      {dkNumbers() && <InvoiceCustSearchForm onSearch={handleSearch}
        searchParams={searchParams}
        dkNumbers={currentDkNumbers ? currentDkNumbers : []}
        setSearchParams={setSearchParams}
        loading={loading}
        error={error} />}

      {!loading && data && <InvoiceTable
        isCustomer={isCustomer}
        invoiceData={data}
        handlePrevPage={loadPrevPage}
        handleNextPage={loadNextPage}
        currentPage={page}
        totalPages={totalPages} />}

      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>

    </Box>
  );
};

export default Invoices;
export type { SearchParams };
